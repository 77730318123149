import { appFeatures } from "../data/AppFeatures";
import { IEstimateResults, TemplateCellTupleMetadata, X_values_map, template_X_values } from "./Azure";
import { logDebug, logError } from "./logger";
export class Roles {
	public static readonly CREATOR_ROLE = "creator";
	public static readonly ADMIN_ROLE = "admin";
	public static readonly USER_ROLE = "user";
	public static readonly READONLY_USER_ROLE = "readonly-user";

	public static readonly CREATOR_ROLES = [Roles.CREATOR_ROLE];
	public static readonly CREATOR_ADMIN_ROLES = [Roles.CREATOR_ROLE, Roles.ADMIN_ROLE];
	public static readonly ADMIN_ROLES = [Roles.ADMIN_ROLE];
	public static readonly ALL_ROLES = [Roles.CREATOR_ROLE, Roles.ADMIN_ROLE, Roles.USER_ROLE];
	public static readonly USER_ROLES = [Roles.USER_ROLE];
}
// TODO :: reshare with server code
class SharedUtils {
	static chunkArray<T>(array: T[], chunkSize: number): T[][] {
		const result: T[][] = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			result.push(array.slice(i, i + chunkSize));
		}
		return result;
	}
	static assert(condition: any, msg: string) {
		if (!condition) {
			throw new Error(msg);
		}
	}

	static getDbPath(path: string) {
		const _path = path.split("/");
		if (SharedUtils.showDebug) {
			_path[0] += "_dev";
		}
		return _path.join("/");
	}

	static splitIgnoreCase(str: string, delimiter: string) {
		return str.split(new RegExp(delimiter, "i"));
	}
	static includeAny(str: string, arr: string[], exact: boolean = false) {
		str = str.toLowerCase();
		for (let i = 0; i < arr.length; i++) {
			if (exact) {
				if (str === arr[i].toLowerCase()) return true;
			} else {
				if (str.includes(arr[i].toLowerCase())) return true;
			}
		}
		return false;
	}
	public static get showDebug() {
		return appFeatures.isDebug();
	}

	public static getFileExtensionUpper3Max(filename: string) {
		if (!filename) return "";
		if (filename.indexOf(".") === -1) return "";
		const ext = filename.split(".").pop();
		if (!ext) return "";
		return ext.toUpperCase().slice(0, 3);
	}

	static isValidDomain(domain: string) {
		// This regex checks for any number of subdomains, followed by a domain name and TLD
		const regex = /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,}$/;
		return regex.test(domain);
	}
	static testPhone(phone: string) {
		const phoneRegex = /^[\+\-\(\)\s0-9]*$/;
		return phoneRegex.test(phone);
	}
	static testEmail(email: string) {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	}
	private static _splitAtFirst(str: string, splitter: string) {
		let index = str.indexOf(splitter);
		if (index === -1) {
			return [str]; // Splitter not found, return the original string in an array
		}

		return [
			str.substring(0, index), // Part before the splitter
			str.substring(index + splitter.length), // Part after the splitter
		];
	}

	static clone(obj: any) {
		return JSON.parse(JSON.stringify(obj));
	}

	private static _replaceAllIgnoreCase(str: string, find: string, replace: string) {
		// Escape special characters in the find string
		let escapedFind = find.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
		let re = new RegExp(escapedFind, "gi"); // 'g' for global, 'i' for ignore case
		return str.replace(re, replace);
	}

	private static _convertMapping(map: { [s: string]: string }, key: string) {
		const ret = map[key];
		if (ret) {
			return ret;
		}
		return key;
	}

	public static haveInputValues(meta: TemplateCellTupleMetadata): boolean {
		const allValues = [
			...(meta.extracted?.extractedKeyInput.acceptedTokens ?? []),
			...(meta.extracted?.extractedValueInput.acceptedTokens ?? []),
			...(meta.extracted?.extractedSelectedWords.acceptedTokens ?? []),
			...(meta.extracted?.extractedSelectedWords.rejectedTokens ?? []),
			...(meta.extracted?.extractedKeyInput.rejectedTokens ?? []),
			...(meta.extracted?.extractedValueInput.rejectedTokens ?? []),
		];
		return allValues.some((v) => v.trim().length > 0);
	}

	private static _roomProcessingMeta = {
		paintLinesAdded: false,
	};

	public static startRoomProcessing() {
		this._roomProcessingMeta.paintLinesAdded = false;
	}

	public static sizeString(x: number): string {
		const x0 = Math.floor(x);
		return `${x0}'${Math.round((x - x0) * 12)}"`;
	}
	public static sizeString2(size: { w: number; h: number }): string {
		return `${SharedUtils.sizeString(size.w)} x ${SharedUtils.sizeString(size.h)}`;
	}

	public static arrayIndexOfCaseInsensitive(arr: string[], v: string): number {
		return arr.findIndex((val) => val.toLowerCase() === v.toLowerCase());
	}

	public static removeDuplicateAndEmpty(arr?: string[]): string[] {
		if (!arr || arr.length === 0) return [];
		return arr.map((i) => i.trim()).filter((v, i, a) => SharedUtils.arrayIndexOfCaseInsensitive(a, v) === i && v.trim().length > 0);
	}

	public static removeAllThese(str: string, remove: string[]): string {
		return remove.reduce((acc, val) => {
			return acc.replace(val, "");
		}, str);
	}
	public static trimAllThese(str: string, remove: string[]): string {
		return remove.reduce((acc, val) => {
			if (acc.startsWith(val)) {
				acc = acc.substring(val.length);
			}
			if (acc.endsWith(val)) {
				acc = acc.substring(0, acc.length - val.length);
			}
			return acc;
		}, str);
	}

	public static processLineItemToEstimateLine(
		mapping: TemplateCellTupleMetadata,
		allAcceptedKeyValues?: string[],
		allAcceptedValueValues?: string[],
		allAcceptedSelectedValues?: string[],
		roomDimensions?: { w: number; h: number }
	): IEstimateResults[] {
		roomDimensions = roomDimensions || { w: 0, h: 0 };
		if (!mapping.estimateMapping || !mapping.quantityMapping) {
			return [];
		}

		if (!allAcceptedKeyValues) {
			allAcceptedKeyValues = mapping.extracted?.extractedKeyInput.acceptedTokens;
		}
		if (!allAcceptedValueValues) {
			allAcceptedValueValues = mapping.extracted?.extractedValueInput.acceptedTokens;
		}
		if (!allAcceptedSelectedValues) {
			allAcceptedSelectedValues = mapping.extracted?.extractedSelectedWords.acceptedTokens;
		}
		if (!mapping.keyConstraints) {
			allAcceptedKeyValues = allAcceptedValueValues;
		}

		let replaceCost = 0;
		let removeCost = 0;
		({ replaceCost, removeCost } = SharedUtils._processLineItemToEstimatePrice(
			allAcceptedKeyValues,
			allAcceptedValueValues,
			mapping,
			replaceCost,
			removeCost
		));
		let tax = parseFloat(mapping.tax || "");
		if (tax === 0 || isNaN(tax)) {
			tax = 7.95;
		}
		let OnP = parseFloat(mapping.OnP || "");
		if (OnP === 0 || isNaN(OnP)) {
			OnP = 20.0;
		}

		// alert(mapping.id);

		const quantity = this._processLineItemToEstimateQuantity(
			mapping.quantityMapping!.split("\n"),
			allAcceptedKeyValues!,
			allAcceptedValueValues,
			allAcceptedSelectedValues
		);
		const retArray = [];
		if (mapping.id?.startsWith("paint_and_stain") && !this._roomProcessingMeta.paintLinesAdded) {
			const remEstimate = {
				finalText: "Mask and prep for paint - plastic, paper, tape, (per LF)",
				quantity: {
					measurement: "LF",
					count: 2 * roomDimensions.h + 2 * roomDimensions.w,
					replaceCount: 0,
					removeCount: 0,
				},
				replace: 1.78,
				remove: 0,
				tax,
				OnP,
				estimate: 1,
				cost: 1,
				costTax: 1,
				costOnP: 1,
			};
			remEstimate.estimate = remEstimate.cost + remEstimate.costTax + remEstimate.costOnP;
			retArray.push(remEstimate);
			this._roomProcessingMeta.paintLinesAdded = true;
		}
		if (replaceCost && removeCost && quantity.removeCount && quantity.replaceCount) {
			{
				// do remove
				const cost = removeCost * quantity.removeCount;
				const costTax = cost * (tax / 100);
				const costOnP = cost * (OnP / 100);
				const remEstimate = {
					finalText: this._processLineItemToEstimateDescription(
						mapping.estimateMapping!.split("\n"),
						allAcceptedKeyValues!,
						allAcceptedValueValues,
						allAcceptedSelectedValues,
						"remove"
					),
					quantity: {
						...quantity,
						count: quantity.removeCount,
					},
					replace: 0,
					remove: removeCost,
					tax,
					OnP,
					estimate: 0,
					cost,
					costTax,
					costOnP,
				};
				remEstimate.estimate = cost + costTax + costOnP;
				retArray.push(remEstimate);
			}
			{
				// do replace
				const cost = replaceCost * quantity.replaceCount;
				const costTax = cost * (tax / 100);
				const costOnP = cost * (OnP / 100);
				const repEstimate = {
					finalText: this._processLineItemToEstimateDescription(
						mapping.estimateMapping!.split("\n"),
						allAcceptedKeyValues!,
						allAcceptedValueValues,
						allAcceptedSelectedValues,
						"replace"
					),
					quantity: {
						...quantity,
						count: quantity.replaceCount,
					},
					replace: replaceCost,
					remove: 0,
					tax,
					OnP,
					estimate: 0,
					cost,
					costTax,
					costOnP,
				};
				repEstimate.estimate = cost + costTax + costOnP;
				retArray.push(repEstimate);
			}
		} else {
			const cost = replaceCost * quantity.count + removeCost * quantity.count;
			const costTax = cost * (tax / 100);
			const costOnP = cost * (OnP / 100);
			const retEstimate = {
				finalText: this._processLineItemToEstimateDescription(
					mapping.estimateMapping!.split("\n"),
					allAcceptedKeyValues!,
					allAcceptedValueValues,
					allAcceptedSelectedValues
				),
				quantity,
				replace: replaceCost || 0,
				remove: removeCost || 0,
				tax,
				OnP,
				estimate: 0,
				cost,
				costTax,
				costOnP,
			};

			retEstimate.estimate = cost + costTax + costOnP;
			retArray.push(retEstimate);

			if (mapping.id?.startsWith("paint_and_stain_seal_prime")) {
				const retEstimate1 = {
					...SharedUtils.clone(retEstimate),
					finalText: "Texture drywall - light hand texture",
					remove: 0,
					replace: 1.13,
				};
				retEstimate1.quantity.measurement = `${quantity.measurement}`;
				// retEstimate1.estimate = retEstimate1.cost + retEstimate1.costTax + retEstimate1.costOnP;
				retArray.push(retEstimate1);
			}
		}
		return retArray;
	}
	private static _processLineItemToEstimatePrice(
		allAcceptedKeyValues: string[] | undefined,
		allAcceptedValueValues: string[] | undefined,
		mapping: TemplateCellTupleMetadata,
		replaceCost: number,
		removeCost: number
	) {
		function checkForXValues(pm: string[]) {
			const valuesToCheck = [...allAcceptedKeyValues!, ...allAcceptedValueValues!].map((val) => val.toLowerCase().trim());
			const ind = pm.findIndex((val) => {
				return (
					(val.startsWith("&xl=") && valuesToCheck.includes("xl")) ||
					(val.startsWith("&m=") && valuesToCheck.includes("m")) ||
					(val.startsWith("&s=") && valuesToCheck.includes("s")) ||
					(val.startsWith("&l=") && valuesToCheck.includes("l"))
				);
			});
			if (ind !== -1) {
				return pm[ind];
			}
			return "";
		}
		const isReplace = [...allAcceptedKeyValues!, ...allAcceptedValueValues!].find((val) => {
			return val.toLowerCase() === "r" || val.toLowerCase() === "rr" || val.toLowerCase() === "r+r";
		});
		const isRemove = [...allAcceptedKeyValues!, ...allAcceptedValueValues!].find((val) => {
			return val.toLowerCase() === "rem" || val.toLowerCase() === "rr" || val.toLowerCase() === "r+r";
		});
		if (mapping.priceMapping) {
			const pm = mapping.priceMapping.toLowerCase().split("\n");
			if (isReplace || !isRemove) {
				let p = pm.find((val) => val.startsWith("&r="));
				if (!p) {
					p = checkForXValues(pm);
					if (!p) {
						p = pm.find((val) => val.startsWith("^=")) || pm.find((val) => val.startsWith("^h="));
					}
				}
				if (!p) {
					logError("invalid price mapping:", mapping.priceMapping);
				} else {
					replaceCost = parseFloat(p.split("=")[1]);
				}
			}
			if (isRemove) {
				let p = pm.find((val) => val.startsWith("&rem="));
				if (!p) {
					p = checkForXValues(pm);
					if (!p) {
						p = pm.find((val) => val.startsWith("^=")) || pm.find((val) => val.startsWith("^h="));
					}
				}
				if (!p) {
					logError("invalid price mapping:", mapping.priceMapping);
				} else {
					removeCost = parseFloat(p.split("=")[1]);
				}
			}
		}
		return { replaceCost, removeCost };
	}

	private static _processLineItemToEstimateQuantity(
		mapping: string[],
		allAcceptedKeyValues: string[],
		allAcceptedValueValues: string[] = [],
		allAcceptedSelectedValues: string[] = []
	) {
		const quantity = {
			replaceCount: 0,
			removeCount: 0,
			count: 0,
			measurement: "",
		};
		for (let i = 0; i < mapping.length; i++) {
			const line = mapping[i].trim();
			if (line.startsWith("^")) {
				const arr = SharedUtils._splitAtFirst(line, "=").map((val) => val.trim().toLowerCase());
				if (arr.length === 2) {
					const [key, value] = arr;
					if (key.toLowerCase() == "^$") {
						const firstKeyNumber = allAcceptedKeyValues.find((val) => {
							return !isNaN(parseInt(val, 10));
						});
						const firstValueNumber = allAcceptedValueValues.find((val) => {
							return !isNaN(parseInt(val, 10));
						});

						if (firstKeyNumber) {
							if (allAcceptedKeyValues.some((val) => val === "r" || val === "rr" || val === "r+r")) {
								quantity.replaceCount = parseInt(firstKeyNumber, 10);
							}
							if (allAcceptedKeyValues.some((val) => val === "rem" || val === "rr" || val === "r+r")) {
								quantity.removeCount = parseInt(firstKeyNumber, 10);
							}
							quantity.count = parseInt(firstKeyNumber, 10);
							quantity.measurement = value.replaceAll("^$", "").trim();
						}

						if (firstValueNumber) {
							if (allAcceptedValueValues.some((val) => val === "r" || val === "rr" || val === "r+r")) {
								quantity.replaceCount = parseInt(firstValueNumber, 10);
							}
							if (allAcceptedValueValues.some((val) => val === "rem" || val === "rr" || val === "r+r")) {
								quantity.removeCount = parseInt(firstValueNumber, 10);
							}
							quantity.count = parseInt(firstValueNumber, 10);
							quantity.measurement = value.replaceAll("^$", "").trim();
							break;
						}
					} else if (key == "^x") {
						if (value.includes("^")) {
							// TODO ::
						} else {
							const regex1 = /^(\d+)(.*?)$/;
							const match1 = value.match(regex1);
							if (match1) {
								quantity.count = parseInt(match1[1], 10);
								quantity.measurement = match1[2].trim().toUpperCase();
							} else {
								quantity.count = parseInt(value, 10);
								quantity.measurement = "EA";
							}
						}
					}
				}
			}
		}
		return quantity;
	}

	public static async sleep(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	private static _processLineItemToEstimateDescription(
		mapping: string[],
		allAcceptedKeyValues: string[],
		allAcceptedValueValues: string[] = [],
		allAcceptedSelectedValues: string[] = [],
		extractionType: string = ""
	) {
		let insideOrSelection = false;
		let insideOrSelectionText = "";
		let text = [] as string[];
		function pushText(value: string) {
			if (insideOrSelection) {
				insideOrSelectionText = value;
			} else {
				text.push(value);
			}
		}

		for (let i = 0; i < mapping.length; i++) {
			const line = mapping[i].trim();
			if (line.startsWith("^") || line.startsWith("&")) {
				if (line === "^{") {
					insideOrSelection = true;
					insideOrSelectionText = "";
				} else if (line === "^}") {
					insideOrSelection = false;
					insideOrSelectionText && text.push(insideOrSelectionText);
				} else {
					if (insideOrSelection && insideOrSelectionText) {
						continue;
					}
					const arr = SharedUtils._splitAtFirst(line, "=").map((val) => val.trim());
					if (arr.length === 2) {
						const [key, value] = arr;
						switch (key.toLowerCase()) {
							case "^x": {
								let added = allAcceptedKeyValues.some((val) => {
									if (template_X_values.includes(val.toLowerCase())) {
										pushText(this._replaceAllIgnoreCase(value, "^x", this._convertMapping(X_values_map, val)));
										// if (insideOrSelection) {
										// 	insideOrSelectionText = this._replaceAllIgnoreCase(value, '^x', this._convertMapping(X_values_map, val));
										// } else {
										// 	text.push(this._replaceAllIgnoreCase(value, '^x', this._convertMapping(X_values_map, val)));
										// }
										return true;
									}
								});
								if (!added) {
									allAcceptedValueValues.some((val) => {
										if (template_X_values.includes(val.toLowerCase())) {
											pushText(this._replaceAllIgnoreCase(value, "^x", this._convertMapping(X_values_map, val)));
											// if (insideOrSelection) {
											// 	insideOrSelectionText = this._replaceAllIgnoreCase(value, '^x', this._convertMapping(X_values_map, val));
											// } else {
											// 	text.push(this._replaceAllIgnoreCase(value, '^x', this._convertMapping(X_values_map, val)));
											// }
											return true;
										}
									});
								}
								continue;
							}
							case "^x^$": {
								// ^x^$ is always interchangable with ^$^x | ^$ ^x | ^x ^$
								let x = "";
								let n = -1;
								const regex0 = /^(.*?)(\d+)$/;
								const regex1 = /^(\d+)(.*?)$/;
								const haveXAndNumber = allAcceptedKeyValues.some((val) => {
									const match0 = val.match(regex0);
									if (match0) {
										x = match0[1];
										n = parseInt(match0[2], 10);
										return x && n > 0;
									}
									const match1 = val.match(regex1);
									if (match1) {
										x = match1[2];
										n = parseInt(match1[1], 10);
										return x && n > 0;
									}
								});
								if (haveXAndNumber) {
									pushText(
										this._replaceAllIgnoreCase(value, "^x", this._convertMapping(X_values_map, x))
											.replace("^$", n.toString())
											.replace("[s]", n === 1 ? "" : "s")
									);
									// if (insideOrSelection) {
									// 	insideOrSelectionText = this._replaceAllIgnoreCase(value, '^x', this._convertMapping(X_values_map, x)).replace('^$', n.toString()).replace('[s]', n === 1 ? '' : 's');
									// } else {
									// 	text.push(this._replaceAllIgnoreCase(value, '^x', this._convertMapping(X_values_map, x)).replace('^$', n.toString()).replace('[s]', n === 1 ? '' : 's'));
									// }
									continue;
								}
								allAcceptedKeyValues.some((val) => {
									if (template_X_values.includes(val.toLowerCase())) {
										x = val;
										return true;
									}
								});
								allAcceptedKeyValues.some((val) => {
									if (isNaN(parseInt(val, 10)) === false) {
										n = parseInt(val, 10);
										return true;
									}
								});
								if (x && n > 0) {
									pushText(
										this._replaceAllIgnoreCase(value, "^x", this._convertMapping(X_values_map, x))
											.replace("^$", n.toString())
											.replace("[s]", n === 1 ? "" : "s")
									);
									// if (insideOrSelection) {
									// 	insideOrSelectionText = this._replaceAllIgnoreCase(value, '^x', this._convertMapping(X_values_map, x)).replace('^$', n.toString()).replace('[s]', n === 1 ? '' : 's');
									// } else {
									// 	text.push(this._replaceAllIgnoreCase(value, '^x', this._convertMapping(X_values_map, x)).replace('^$', n.toString()).replace('[s]', n === 1 ? '' : 's'));
									// }
									continue;
								}
								break;
							}
							case "^$": {
								let n = -1;
								allAcceptedKeyValues.some((val) => {
									if (isNaN(parseInt(val, 10)) === false) {
										n = parseInt(val, 10);
										return true;
									}
								});
								if (n < 0) {
									allAcceptedValueValues.some((val) => {
										if (isNaN(parseInt(val, 10)) === false) {
											n = parseInt(val, 10);
											return true;
										}
									});
								}
								pushText(this._replaceAllIgnoreCase(value, "^$", n.toString()).replace("[s]", n === 1 ? "" : "s"));
								// if (insideOrSelection) {
								// 	insideOrSelectionText = this._replaceAllIgnoreCase(value, '^$', n.toString()).replace('[s]', n === 1 ? '' : 's');
								// } else {
								// 	text.push(insideOrSelectionText = this._replaceAllIgnoreCase(value, '^$', n.toString()).replace('[s]', n === 1 ? '' : 's'));
								// }
								continue;
							}
							case "&r": {
								if (!extractionType || extractionType === "replace") {
									pushText(value);
								}
								break;
							}
							case "&rem": {
								if (!extractionType || extractionType === "remove") {
									pushText(value);
								}
								break;
							}
							case "^": {
								if (insideOrSelection) {
									insideOrSelectionText = value;
								} else {
									logError("invalid mapping:", line);
								}
							}
						}
						if (key.toLowerCase().startsWith("^s")) {
							let _key = key.substring(2).toLowerCase();
							_key = _key.replace("]", "").replace("[", "");
							let selectedValues = _key.split(/\s+/);
							selectedValues.some((val) => {
								if (val === "|") return;
								if (allAcceptedSelectedValues.map((s) => s.toLowerCase()).includes(val.toLowerCase())) {
									pushText(this._replaceAllIgnoreCase(value, "^s", val).replace("(", "").replace(")", ""));
									// if (insideOrSelection) {
									// 	insideOrSelectionText = this._replaceAllIgnoreCase(value, '^s', val);
									// } else {
									// 	text.push(this._replaceAllIgnoreCase(value, '^s', val));
									// }
									return true;
								}
							});
						} else if (key.startsWith("&") && key.endsWith("^$")) {
							if (key.length === 4) {
								const regex = /^(.*?)(\d+)$/;
								allAcceptedKeyValues.forEach((val) => {
									val = val.toLowerCase();
									const match = val.match(regex);
									if (match) {
										const _text = match[1];
										const number = parseInt(match[2], 10);
										if (_text.length === 1) {
											if (`&${_text}^$` === key.toLowerCase()) {
												pushText(
													this._replaceAllIgnoreCase(value, "^$", number.toString()).replace(
														"[s]",
														number === 1 ? "" : "s"
													)
												);
												// if (insideOrSelection) {
												// 	insideOrSelectionText = this._replaceAllIgnoreCase(value, '^$', number.toString()).replace('[s]', number === 1 ? '' : 's');
												// } else {
												// 	text.push(this._replaceAllIgnoreCase(value, '^$', number.toString()).replace('[s]', number === 1 ? '' : 's'));
												// }
											}
										} else {
											logError("invalid value:", val);
										}
									}
								});
							} else {
								logError("invalid mapping:", line);
							}
						}
					} else {
						logError("invalid mapping:", line);
					}
				}
			} else {
				text.push(line);
			}
		}
		const finalText = text.join(" ");
		// logDebug('retEstimate', finalText);
		return finalText;
	}
}

export default SharedUtils;
