import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { getUserStore, isExternalSubmissionPage } from "../../../data/DataStores";
import SecureImage from "../utils/SecureImage";
import { logDebug } from "../../../shared/logger";
import RestAPI from "../../../utils/restAPI";
import { appFeatures } from "../../../data/AppFeatures";
import TopMostPortal from "../utils/Portals";
import LassoMVPUserSettingsModal from "../../../MVP/components/LassoMVPUserSettingsModal";
import Analytics from "../../../utils/Analytics";

const LassoUserImage: React.FC = observer(() => {
	const userStore = getUserStore();
	const [showMenu, setShowMenu] = useState(false);
	const [showUserSettings, setShowUserSettings] = useState(false);
	const [photoURL, setPhotoURL] = useState(userStore.user?.photoURL || "");

	if (isExternalSubmissionPage()) {
		return null;
	}
	useEffect(() => {
		setPhotoURL(userStore.user?.photoURL || "");
	}, [userStore.user?.photoURL]);

	useEffect(() => {
		document.removeEventListener("click", onBackgroundClick);
		document.addEventListener("click", onBackgroundClick);
		return () => {
			document.removeEventListener("click", onBackgroundClick);
		};
	}, []);

	const onBackgroundClick = (e: any) => {
		let element = e.target;
		while (element) {
			if (element.getAttribute && element.id === "filter") {
				return;
			}
			element = element.parentElement;
		}
		setShowMenu(false);
	};
	const firstName = userStore.user?.firstName || " ";
	const lastName = userStore.user?.lastName || " ";
	const initials = (userStore.user ? `${firstName[0]}${lastName[0]}` : "U").trim().toUpperCase();
	return (
		<div id="filter">
			<div>
				<div
					className=" w-10 h-10 rounded-[200px] bg-gray-100 btn"
					onClick={() => {
						setShowMenu((v) => !v);
						Analytics.Instance.track("UserImageClick", {
							showMenu: !showMenu,
						});
					}}
				>
					{/* TODO :: this is a bit of a mess with handling photoURL. Maybe save the whole path there!!! */}
					{photoURL ? (
						<SecureImage
							key={photoURL}
							className="w-10 h-10"
							w={16}
							h={16}
							defaultImage={RestAPI.getStorageFileUrl(photoURL)}
						/>
					) : (
						<div className="w-10 h-10   justify-center items-center inline-flex">
							<div className="w-10 text-center text-slate-600 text-lg font-medium font-['Hanken Grotesk'] leading-tight">
								{initials}
							</div>
						</div>
					)}
				</div>
			</div>

			{showMenu && (
				<div className="absolute right-16 top-16 z-10">
					<div className="bg-white rounded-lg shadow border border-gray-200 flex-col justify-start items-start flex">
						{!appFeatures.isEstimatics && (
							<div className="self-stretch h-[50px] py-1 border-b border-gray-200 flex-col justify-start items-start flex">
								<div className="self-stretch px-1.5 py-0.5 justify-start items-center inline-flex">
									<div className="grow shrink basis-0 h-[38px] px-2.5 py-[9px] rounded-md justify-start items-ce   nter gap-3 flex">
										<div className="grow shrink basis-0 h-5 justify-start items-center gap-2 flex">
											<div className="w-4 h-4 relative" />
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Organization & Team
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
						<div
							className="self-stretch hover:bg-sky-50 h-[50px] border-b border-gray-200 justify-start items-start flex px-3 btn"
							onClick={() => {
								setShowUserSettings(true);
								setShowMenu(false);
								Analytics.Instance.track("ShowUserSettingsClicked");
							}}
						>
							<div className="px-2 py-4 flex">
								<img src="/assets/ui/user-01.svg" className="w-5 h-5  mr-2" />
								<div className=" text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
									User settings
								</div>
							</div>
						</div>
						<div
							className="self-stretch h-[50px] hover:bg-sky-50 border-b border-gray-200 justify-start items-start flex px-3 btn"
							onClick={async () => {
								Analytics.Instance.track("LogoutClicked");
								await userStore.logout();
								location.reload();
								// setShowMenu(false);
							}}
						>
							<div className="px-2 py-4 flex">
								<img src="/assets/ui/log-out-01.svg" className="w-5 h-5  mr-2" />
								<div className=" text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">Log out</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{showUserSettings && (
				<TopMostPortal>
					<LassoMVPUserSettingsModal
						editingUser={{
							role: "-",
							email: userStore.user?.email || "",
							firstName: userStore.user?.firstName || "",
							lastName: userStore.user?.lastName || "",
							photoURL: userStore.user?.photoURL || "",
						}}
						cancel={() => setShowUserSettings(false)}
						update={async (updatedUser) => {
							const newUserData = await RestAPI.fetchAPI("/rest/access/updateMyUser", "POST", {
								myUser: {
									firstName: updatedUser.firstName,
									lastName: updatedUser.lastName,
									photoURL: updatedUser.photoURL,
								},
							});
							if (newUserData) {
								userStore.updateRegisteredUser(newUserData);
							}
							setShowUserSettings(false);
						}}
					/>
				</TopMostPortal>
			)}
		</div>
	);
});

export default LassoUserImage;
