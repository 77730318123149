import { useEffect } from "react";
import { getEstimaticsCurrentProjectStore } from "../../data/DataStores";
import NewProjectModal from "../components/Estimatics/EstimaticsNewProjectModal";
import { NewProjectWizardStep1 } from "../components/NewProjectWizard/NewProjectWizardStep1";
import { NewProjectWizardStep2 } from "../components/NewProjectWizard/NewProjectWizardStep2";
import { NewProjectWizardStep3 } from "../components/NewProjectWizard/NewProjectWizardStep3";
import GenericWizardLeftBar from "../CreatorTools/MappingTool/components/GenericWizardLeftBar";
import GenericWizardRightBar from "../CreatorTools/MappingTool/components/GenericWizardRightBar";

const ExternalSubmissionPage: React.FC = () => {
	const steps = [NewProjectWizardStep1, NewProjectWizardStep2, NewProjectWizardStep3];
	const newProjectStore = getEstimaticsCurrentProjectStore();
	useEffect(() => {
		newProjectStore.wizardTitles.includes("Upload Estimate") || newProjectStore.wizardTitles.push("Upload Estimate");
	}, []);
	newProjectStore.showNewProjectModal = true;
	return (
		<div className="w-full h-full justify-center flex">
			<div className="w-[960px]  bg-red-100 flex">
				<GenericWizardLeftBar manager={newProjectStore} />
				<GenericWizardRightBar manager={newProjectStore} steps={steps} />
			</div>
		</div>
	);
};

export default ExternalSubmissionPage;
