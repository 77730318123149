import React, { useEffect, useRef, useState } from "react";
import {
	EstimaticsFileTypes,
	EstimaticsProjectStoreType,
	IWizardManager,
	getEstimaticsCurrentProjectStore,
	getUserStore,
} from "../../../data/DataStores";
import { observer } from "mobx-react";
import { logDebug, logError } from "../../../shared/logger";
import RestAPI from "../../../utils/restAPI";

import { AttachmentFile } from "../../../shared/Azure";
import SharedUtils from "../../../shared/SharedUtils";
import Analytics from "../../../utils/Analytics";

// TODO :: move to utils
export function getHumanReadableSize(size: number): string {
	const units = ["B", "KB", "MB", "GB", "TB"];
	const fixed = [0, 0, 1, 2, 2];
	let unitIndex = 0;
	let fileSize = size;

	while (fileSize >= 1024 && unitIndex < units.length - 1) {
		fileSize /= 1024;
		unitIndex++;
	}

	return `${fileSize.toFixed(fixed[unitIndex])} ${units[unitIndex]}`;
}

const NewProjectWizardStyledDownloadBox: React.FC<{ file: AttachmentFile }> = observer(({ file }) => {
	const newEstimateStore = getEstimaticsCurrentProjectStore();
	const [uploadedSofar, setUploadedSofar] = useState<number>(0);

	useEffect(() => {
		if (file.remotePath && file.uploadedSofar === 100) {
			setUploadedSofar(100);
		} else {
			setUploadedSofar((file.uploadedSofar || 0) * 0.9); //  don't complete the download bar until we have remotePath
		}
	}, [file.uploadedSofar, file.remotePath]);
	// TODO :: move to Utils
	function getPercentage(): string {
		return `${(uploadedSofar || 0).toFixed(0)}%`;
	}

	return (
		<div
			className={`${
				uploadedSofar >= 0 ? " bg-white border-gray-200 text-slate-600" : "bg-[#FFFBFA] border-red-300 text-red-700"
			} relative w-[31%] h-16 p-2  rounded-xl border  justify-start items-start gap-1 flex flex-col justify-center  text-sm font-medium font-['Hanken Grotesk']`}
		>
			<div
				className="absolute  right-2 top-5 cursor-pointer w-6 h-6"
				onClick={() => {
					newEstimateStore.updateEstimaticsDataFileDeleted(file.uploadId!, true);
				}}
			>
				<img className="w-6 h-6" src={`/assets/ui/trash-0${uploadedSofar < 0 ? "4" : "3"}.svg`} />
			</div>

			<div className="w-[85%] no-break mb-1 -mt-1">{file.originalname}</div>
			{uploadedSofar >= 0 && uploadedSofar < 100 && (
				<div className="w-[50%] h-2 relative rounded-lg flex ">
					<div className="w-full h-2 left-0 top-0 absolute bg-gray-200 rounded" />
					<div style={{ width: getPercentage() }} className=" h-2 left-0 top-0 absolute bg-blue-600 rounded" />
				</div>
			)}
			{uploadedSofar === 100 && (
				<div className="text-slate-600 text-sm font-normal h-2 leading-tight ">
					<div className="relative -top-1">{getHumanReadableSize(file.size) + " - 100%"}</div>
				</div>
			)}
		</div>
	);
});
const NewProjectWizardStyledTextArae: React.FC<{
	value: string;
	placeholder: string;
	onChange: (v: string) => void;
}> = ({ value, placeholder, onChange }) => {
	return (
		<div className="h-full self-stretch grow shrink basis-0 px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex">
			<div className="h-full grow shrink basis-0 h-5 justify-start items-center gap-2 flex">
				<textarea
					className="focus:outline-none scrollbar-thin w-full h-full text-[#101828] text-sm font-normal font-['Hanken Grotesk'] leading-tight"
					value={value}
					placeholder={placeholder}
					onChange={(e) => onChange(e.target.value)}
				></textarea>
			</div>
		</div>
	);
};

const NewProjectWizardStyledUploadBox: React.FC<{ fileType: EstimaticsFileTypes }> = observer(({ fileType }) => {
	const newProjectStore = getEstimaticsCurrentProjectStore();
	const userStore = getUserStore();
	const uploadFileInputRef = useRef<HTMLInputElement>(null);
	const accept = fileType === "image" ? "image/*" : "*/*";
	const [inputKey, setInputKey] = useState(0);

	const handleDragOver = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		const files = Array.from(e.dataTransfer.files) as File[];
		RestAPI.uploadFiles(files, fileType, newProjectStore);
	};

	return (
		<div className="w-full" onDragOver={handleDragOver} onDrop={handleDrop}>
			<div
				className="w-full cursor-pointer grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex"
				onClick={() => {
					// logDebug('upload clicked')
					if (uploadFileInputRef.current) {
						uploadFileInputRef.current.click();
					}
				}}
			>
				<div className="self-stretch  flex-col justify-center items-center gap-4 flex">
					<div className="self-stretch grow shrink basis-0 px-6 py-4 bg-white rounded-xl border   border-gray-300 flex-col justify-center items-center gap-1 flex">
						<div className="self-stretch h-[72px] flex-col justify-start items-center gap-3 flex">
							<div className="w-10 h-10 p-2.5 bg-gray-100 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex relative">
								<img
									src="/assets/ui/upload-cloud-02.svg"
									className="w-5 h-5 absolute flex-col justify-start items-start flex"
								/>
							</div>
							<div className="self-stretch h-5 flex-col justify-start items-center gap-1 flex">
								<div className="self-stretch justify-center items-start gap-1 inline-flex">
									<div className="justify-center items-center gap-2 flex">
										<div className="justify-start items-center gap-2 flex">
											<div className="text-blue-900 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">
												Click to upload
											</div>
										</div>
									</div>
									<div className="text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">
										or drag and drop
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<input
				key={inputKey}
				ref={uploadFileInputRef}
				type="file"
				accept={accept}
				className="hidden"
				multiple={true}
				onChange={async (e) => {
					setInputKey((v) => v + 1);
					const files = uploadFileInputRef.current!.files;
					RestAPI.uploadFiles(files, fileType, newProjectStore);
				}}
			/>
		</div>
	);
});

const NewProjectWizardUploadsContainer: React.FC<{ fileType: EstimaticsFileTypes }> = observer(({ fileType }) => {
	const newProjectStore = getEstimaticsCurrentProjectStore();
	return (
		<div className="w-full h-auto  ml-4 mt-2 flex flex-wrap gap-2">
			{newProjectStore.estimaticsData.files
				.filter((f) => !f.deleted && f.fileType === fileType)
				.map((f, i) => {
					return <NewProjectWizardStyledDownloadBox file={f} key={f.originalname + -i} />;
				})}
		</div>
	);
});

const NewProjectWizardStepDocumentationBox: React.FC<{ fileType: EstimaticsFileTypes; title: string; text: string }> = observer(
	({ fileType, title, text }) => {
		return (
			<div className="border-l-2 border-blue-600 self-stretch justify-between items-start flex flex-col">
				<div className="flex w-full justify-between">
					<div className="w-[50%]  pl-4  justify-start items-start flex flex-col font-['Hanken Grotesk']">
						<div className="mb-2 text-slate-700 text-sm font-bold  leading-tight">{title}</div>
						<div className="pr-12 text-gray-500 text-xs font-normal leading-[18px]">{text}</div>
					</div>
					<div className="w-[50%] mr-4">
						<NewProjectWizardStyledUploadBox fileType={fileType} />
					</div>
				</div>
				<NewProjectWizardUploadsContainer fileType={fileType} />
			</div>
		);
	}
);

export const NewProjectWizardStep2: React.FC<{
	onNext: (n: number) => void;
	onBack: () => void;
	onCancel: () => void;
	manager?: IWizardManager;
}> = observer(({ onNext, onBack, onCancel, manager }) => {
	const newProjectStore = getEstimaticsCurrentProjectStore();
	const userStore = getUserStore();
	const [nextEnabled, setNextEnabled] = useState(false);
	useEffect(() => {
		let canItEvenBeEnabled = true;
		const firstPageFields = userStore.requiredFields.filter(
			(field) => field === "ticSheets" || field === "photos" || field === "links" || field === "notes" || field === "attachments"
		);
		logDebug("NewProjectWizardStep2 mounted", firstPageFields);
		if (firstPageFields.length > 0) {
			canItEvenBeEnabled = firstPageFields.every((field) => {
				if (field === "ticSheets") {
					return newProjectStore.estimaticsData.files.some((file) => file.fileType === EstimaticsFileTypes.tic && !file.deleted);
				}
				if (field === "photos") {
					return newProjectStore.estimaticsData.files.some(
						(file) => file.fileType === EstimaticsFileTypes.image && !file.deleted
					);
				}
				if (field === "attachments") {
					return newProjectStore.estimaticsData.files.some(
						(file) => file.fileType === EstimaticsFileTypes.other && !file.deleted
					);
				}
				// @ts-ignore
				logDebug("NewProjectWizardStep2 field", field, newProjectStore.estimaticsData[field]);
				// @ts-ignore
				return newProjectStore.estimaticsData[field] && newProjectStore.estimaticsData[field].length > 0;
			});
		}

		setNextEnabled(
			canItEvenBeEnabled &&
				newProjectStore.estimaticsData.projectName.trim().length > 0 &&
				(!newProjectStore.estimaticsData.email || SharedUtils.testEmail(newProjectStore.estimaticsData.email.trim())) &&
				(!newProjectStore.estimaticsData.phone || SharedUtils.testPhone(newProjectStore.estimaticsData.phone.trim()))
		);
	}, [
		newProjectStore.estimaticsData.notes.length,
		newProjectStore.estimaticsData.links.length,
		newProjectStore.estimaticsData.files.length,
	]);

	return (
		<div className="px-6 flex justify-between  flex-col h-full">
			<div className="mt-[34px] self-stretch  flex-col justify-start items-start gap-16 flex h-full overflow-y-auto scrollbar-thin ">
				<div className="self-stretch justify-start items-start gap-2.5 inline-flex">
					<div className="grow shrink basis-0 text-gray-900 text-lg font-normal font-['Hanken Grotesk'] leading-7">
						Documentation
					</div>
				</div>
				<NewProjectWizardStepDocumentationBox
					fileType={EstimaticsFileTypes.tic}
					title={"Tic sheet" + (getUserStore().requiredFields.includes("ticSheets") ? " *" : "")}
					text="If a tic sheet was completed for this project, please upload it here"
				/>
				<NewProjectWizardStepDocumentationBox
					fileType={EstimaticsFileTypes.image}
					title={"Photos" + (getUserStore().requiredFields.includes("photos") ? " *" : "")}
					text="Please share any photos taken of the project here. If photos for this project are stored in a cloud storage location, please place the url in the Links section below"
				/>
				<NewProjectWizardStepDocumentationBox
					fileType={EstimaticsFileTypes.other}
					title={"Other documentation" + (getUserStore().requiredFields.includes("attachments") ? " *" : "")}
					text="Please share any other relevant files for the project here"
				/>
				<div className="self-stretch justify-between items-start inline-flex">
					<div className="grow shrink basis-0 h-[90px] justify-between items-start gap-8 flex">
						<div className="w-[248px] self-stretch pl-4 border-l-2 border-blue-600 justify-start items-start flex">
							<div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
								<div className="text-slate-700 text-sm font-bold font-['Hanken Grotesk'] leading-tight">
									Links (url) {getUserStore().requiredFields.includes("links") ? " *" : ""}
								</div>
								<div className="self-stretch text-gray-500 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
									Input links to project resources- You can input multiple urls by entering each on a new line
								</div>
							</div>
						</div>
						<div className="grow shrink basis-0 h-[106px] max-w-[336px] mr-4 flex-col justify-start items-start gap-2.5 inline-flex">
							<div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-0.5 flex">
								<div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
									<NewProjectWizardStyledTextArae
										value={newProjectStore.estimaticsData.links[0]}
										placeholder="Input links"
										onChange={(v) => newProjectStore.setLink(0, v)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="self-stretch justify-between items-start inline-flex">
					<div className="grow shrink basis-0 pb-8 justify-between items-start gap-8 flex">
						<div className="w-[248px] self-stretch pl-4 border-l-2 border-blue-600 justify-start items-start flex">
							<div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
								<div className="text-slate-700 text-sm font-bold font-['Hanken Grotesk'] leading-tight">
									Internal note {getUserStore().requiredFields.includes("notes") ? " *" : ""}
								</div>
								<div className="self-stretch text-gray-500 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
									Please share anything else you think the estimating team should be aware of
								</div>
							</div>
						</div>
						<div className="grow shrink basis-0 h-[106px] max-w-[336px] mr-4 flex-col justify-start items-start gap-2.5 inline-flex">
							<div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-0.5 flex">
								<div className="self-stretch grow shrink basis-0 flex-col justify-start items-start gap-1.5 flex">
									<NewProjectWizardStyledTextArae
										value={newProjectStore.estimaticsData.notes[0]}
										placeholder="Input notes"
										onChange={(v) => (newProjectStore.estimaticsData.notes[0] = v)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="h-24 px-6 py-6 border-t border-gray-200 justify-between items-start w-full flex">
				<div
					className="w-24 px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex btn "
					onClick={onBack}
				>
					<div className="justify-start items-center gap-2 flex ">
						<div className="text-slate-700 text-base font-semibold font-['Hanken Grotesk'] leading-normal select-none">
							Back
						</div>
					</div>
				</div>
				<div className=" items-center  flex">
					{
						<div
							className={` h-11 w-24 px-[18px] py-2.5  rounded-lg  border ${
								nextEnabled && newProjectStore.nextDisabled === 0
									? "btn shadow bg-blue-900 border-blue-900 text-white"
									: "bg-gray-100 border-gray-200 text-gray-300"
							} justify-center items-center gap-2 flex `}
							onClick={() => {
								if (nextEnabled && newProjectStore.nextDisabled === 0) {
									Analytics.Instance.track("NewProjectWizardStep2Next", {
										notesCount: newProjectStore.estimaticsData.notes.length,
										linksCount: newProjectStore.estimaticsData.links.length,
										filesCount: newProjectStore.estimaticsData.files.filter((f) => !f.deleted).length,
										deletedFilesCount: newProjectStore.estimaticsData.files.filter((f) => f.deleted).length,
									});
									onNext(3);
								}
							}}
						>
							<div className="justify-start items-center gap-2 flex  ">
								<div className=" text-base font-semibold font-['Hanken Grotesk'] leading-normal select-none">Next</div>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
});
