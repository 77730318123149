import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LassoSimpleButtonFull, LassoToggle } from "../../components/ui/utils/LassoButtons";
import SharedUtils, { Roles } from "../../shared/SharedUtils";
import { logDebug, logError } from "../../shared/logger";
import { getAppStore, getUserStore } from "../../data/DataStores";
import LoadingAnimation from "../../components/ui/utils/LoadingAnimation";
import "./LasssoMVPOrganizationSettingsPage.scss";
import { observer } from "mobx-react";
import { IOrganizationCreateObject, IOrganizationCreateUser } from "../../shared/interfaces";
import TopMostPortal from "../../components/ui/utils/Portals";
import LassoMVPUserSettingsModal from "../components/LassoMVPUserSettingsModal";
import RestAPI from "../../utils/restAPI";
import SecureImage from "../../components/ui/utils/SecureImage";

const LassoMVPUserTable: React.FC = observer(() => {
	const appStore = getAppStore();
	const [editingUser, setEditingUser] = useState<IOrganizationCreateUser | null>(null);
	return (
		<div className="w-[880px] max-h-[540px] overflow-y-auto pt-6 bg-white rounded-2xl shadow border border-gray-200 flex-col justify-start items-start gap-6 inline-flex">
			<div className="self-stretch px-6 justify-between items-center inline-flex">
				<div className="text-gray-900 text-xl font-semibold font-['Hanken Grotesk'] leading-7">
					Organization users ({appStore.selectedOrganization!.users.length})
				</div>
				<div className="w-[231px] justify-end items-center gap-6 flex">
					<div className="justify-end items-center flex">
						<div className="w-11 h-11 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex">
							<img className="w-5 h-5 relative" src="/assets/ui/search-lg.svg" />
						</div>
					</div>
					<div className="h-11 self-stretch origin-top-left border border-gray-200"></div>
					<LassoSimpleButtonFull
						caption="Add New User"
						onClick={() => {
							setEditingUser({
								role: appStore.selectedOrganization!.users.length === 0 ? Roles.ADMIN_ROLE : Roles.USER_ROLE,
								email: "",
								firstName: "",
								lastName: "",
								photoURL: "",
							});
						}}
					/>
				</div>
			</div>
			<div className="w-full ">
				<div className="flex bg-gray-50 border-t border-b border-gray-200 px-6 py-4">
					<div className="table-head w-[35%] ">Name</div>
					<div className="table-head w-[15%]">Role</div>
					<div className="table-head w-[30%] ">Email</div>
				</div>
				<div className="flex  flex-col">
					{appStore.selectedOrganization?.users.map((user, index) => {
						return (
							<div key={index} className="w-full flex table-row bg-gray-50 border-t border-b border-gray-200 px-6 py-4">
								<div className="table-text w-[35%]">{`${user.firstName || ""} ${user.lastName || ""}`}</div>
								<div className="table-text w-[15%]">{user.role}</div>
								<div className="table-text w-[30%]">{user.email}</div>
								<button className="only-hover-edit" onClick={() => {}}>
									<img src="/assets/ui/edit-03.svg"></img>
								</button>
								<button className="only-hover-trash" onClick={() => {}}>
									<img src="/assets/ui/trash-01.svg"></img>
								</button>
							</div>
						);
					})}
				</div>
			</div>
			{editingUser && (
				<TopMostPortal>
					<LassoMVPUserSettingsModal
						usersForValidation={appStore.selectedOrganization!.users}
						editingUser={editingUser}
						cancel={() => setEditingUser(null)}
						update={(editedUser) => {
							setEditingUser(null);
							//@ts-ignore TODO :: fix this
							appStore.selectedOrganization!.users.push(editedUser);
						}}
					/>
				</TopMostPortal>
			)}
		</div>
	);
});

const LassoOrganizationSettings: React.FC<{ startInEditMode: boolean }> = observer(({ startInEditMode }) => {
	const userStore = useRef(getUserStore());
	const appStore = useRef(getAppStore());
	const [isEditing, setIsEditing] = useState(startInEditMode);
	const [allowDomain, setAllowDomain] = useState(!!appStore.current.selectedOrganization!.allowedDomain);
	useEffect(() => {}, [appStore.current.selectedOrganization]);

	// useEffect(() => {
	// 	let valid = true;
	// 	try {
	// 		JSON.parse(aiModels);
	// 	} catch (e) {
	// 		valid = false;
	// 	}
	// 	if (allowDomain) {
	// 		valid = valid && SharedUtils.isValidDomain(domain);
	// 	}
	// 	valid = valid && appStore.selectedOrganization!.organizationName.length > 0 && organizationDescription.length > 0;
	// 	// valid = valid && users.filter(u => u.role === Roles.ADMIN_ROLE).length > 0;
	// 	setDataValid(valid);
	// }, [allowDomain, domain, aiModels, organizationDescription, appStore.selectedOrganization]);

	return (
		appStore.current.selectedOrganization && (
			<div className="max-w-[448px] overflow-y-auto h-[571px] flex-col justify-start items-start gap-5 inline-flex">
				<div className="self-stretch pb-4 border-b border-gray-200 justify-between items-end inline-flex">
					{/* <input key={forceRender} type="file" id="files" accept="image/*" ref={uploadRef} style={{ display: 'none' }}
				onChange={async (e) => {
					if (uploadRef.current!.files && uploadRef.current!.files.length > 0) {
						// TODO :: loading animation
						const formData = new FormData();
						formData.append('destination', `organizations/${userStore.current.user?.organizationId}/users/${userStore.current.user?.lassoUserId}/private`);
						for (let i = 0; i < uploadRef.current!.files.length; i++) {
							formData.append('files', uploadRef.current!.files[i]);
						}
						try {
							const data = await RestAPI.fetchAPI('/rest/files/upload', 'POST', formData);
							appStore.current.selectedOrganization!.organizationLogo = data[0].destination;
							logDebug('Upload dest:', data[0].destination);
						} catch (error) {
							logError('Error uploading files', error);
						}
						setForceRender(forceRender + 1);
					}
				}}
			/> */}
					{/* TODO :: modify class so there will be no straching of the image */}
					<div className={`w-[183px] h-[60px] justify-start items-center flex `}>
						<SecureImage
							onUrlChange={(url) => (appStore.current.selectedOrganization!.organizationLogo = url)}
							key={isEditing.toString()}
							destination={
								isEditing
									? `organizations/${userStore.current.user?.organizationId}/users/${userStore.current.user?.lassoUserId}/private`
									: undefined
							}
							w={183}
							h={60}
							defaultImage={
								appStore.current.selectedOrganization!.organizationLogo
									? `${RestAPI.getStorageFileUrl(appStore.current.selectedOrganization!.organizationLogo)}`
									: "/assets/ui/org-placeholder.png"
							}
						/>
					</div>
					<div
						onClick={() => {
							setIsEditing(true);
						}}
						className="cursor-pointer w-10 p-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-2 flex"
					>
						<img src="/assets/ui/edit-03.svg"></img>
					</div>
				</div>
				<div className="self-stretch flex-col justify-start items-start gap-10 flex">
					<div className="self-stretch  flex-col justify-start items-start gap-2 flex">
						<div className="w-[448px] text-gray-900 text-base font-semibold fo nt-['Hanken Grotesk'] leading-snug">
							Organization name
						</div>
						<input
							disabled={!isEditing}
							className="w-full transparent-input"
							placeholder={"Organization Name"}
							value={appStore.current.selectedOrganization!.organizationName}
							onChange={(e) => (appStore.current.selectedOrganizationName = e.target.value)}
						/>
					</div>
					<div className="self-stretch flex-col justify-start items-start flex">
						<div className="justify-start items-start gap-1 inline-flex">
							<div className="text-justify text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug">
								Login type
							</div>
							<div className="w-5 self-stretch pt-0.5 justify-start items-center gap-2.5 flex">
								<div className="w-5 h-5 justify-center items-center flex">
									<div className="w-5 h-5 relative flex-col justify-start items-start flex" />
								</div>
							</div>
						</div>
						<div className="self-stretch pt-2 justify-between items-end inline-flex">
							<div className="w-full justify-between items-center gap-2 flex ">
								<div className="justify-start items-center gap-1 flex min-h-[42px]">
									<LassoToggle
										disabled={!isEditing}
										checked={allowDomain}
										onChange={(val) => {
											setAllowDomain(val);
											if (!val) {
												appStore.current.selectedOrganizationAllowedDomain = "";
											}
										}}
									/>
									<div className="text-justify text-slate-600 text-base font-normal font-['Hanken Grotesk'] leading-normal">
										Allow domain login
									</div>
								</div>
								<div className="flex justify-center items-center">
									<div hidden={!allowDomain}>@</div>
									<input
										className="transparent-input"
										value={appStore.current.selectedOrganization.allowedDomain}
										hidden={!allowDomain}
										onChange={(e) => (appStore.current.selectedOrganizationAllowedDomain = e.target.value)}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="self-stretch flex-col justify-start items-start gap-2 flex">
						<div className="self-stretch text-justify text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug">
							Description
						</div>
						<textarea
							disabled={!isEditing}
							className="w-full min-h-32 transparent-input"
							placeholder={"Organization Description"}
							value={appStore.current.selectedOrganization.organizationDescription}
							onChange={(e) => (appStore.current.selectedOrganizationDescription = e.target.value)}
						/>
					</div>
					<div className="self-stretch h-[150px] flex-col justify-start items-start gap-2 flex">
						<div className="self-stretch text-justify text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug">
							AI Model
						</div>
						<textarea
							disabled={!isEditing}
							onChange={(e) => (appStore.current.selectedOrganizationAIModels = e.target.value)}
							className="w-full h-32 transparent-input"
							value={appStore.current.selectedOrganization.aiModels}
						/>
					</div>
					{/* <div className="self-stretch h-[150px] flex-col justify-start items-start gap-2 flex">
				<div className="self-stretch text-justify text-gray-900 text-base font-semibold font-['Hanken Grotesk'] leading-snug">Custom Settings</div>
				<textarea
					disabled={!isEditing}
					onChange={(e) => appStore.current.selectedOrganizationCustomSettings = e.target.value}
					className='w-full h-32 transparent-input'
					value={appStore.current.selectedOrganization.customSettings} />
			</div> */}
				</div>
			</div>
		)
	);
});

export const EstimaticsDataKeysAsObject: { [s: string]: string } = {
	projectName: "Project Name",
	estimateReferenceNumber: "Estimate Reference Number",
	requesterName: "Requester Name",
	email: "Email",
	phone: "Phone",
	propertyAddress: "Property Address",
	insuranceCarrier: "Insurance Carrier",
	claimNumber: "Claim Number",
	tpa: "TPA",
	// dateCreated: "Date Created",
	dateContacted: "Date Contacted",
	dateOfLoss: "Date of Loss",
	office: "Office",
	assignee: "Assignee",
	status: "Status",
	approximateClaim: "Approximate Claim",
	category: "Category",
	typeOfLoss: "Type of Loss",
	// second page
	links: "Links",
	notes: "Notes",
	ticSheets: "Tic Sheets",
	photos: "Photos",
	attachments: "Attachments",
};

const LassoMVPOrganizationCustomization: React.FC = observer(() => {
	const appStore = getAppStore();
	const [status, setStatus] = useState<{ name: string; color: string }[]>([]);
	const [name, setName] = useState("");
	const [office, setOffice] = useState("");
	const [offices, setOffices] = useState<string[]>([]);
	const [color, setColor] = useState("");
	const [tpa, setTpa] = useState("");
	const [tpas, setTpas] = useState<string[]>([]);
	const [carrier, setCarrier] = useState("");
	const [carriers, setCarriers] = useState<string[]>([]);
	const [extraField, setExtraField] = useState("");
	const [extraFields, setExtraFields] = useState<string[]>([]);
	const [requiredFields, setRequiredFields] = useState<string[]>([]);
	useEffect(() => {
		logDebug("Custom settings from effect:", appStore.selectedOrganization?.customSettings);
		let settings = JSON.parse(appStore.selectedOrganization?.customSettings || "{}");
		if (typeof settings === "string") {
			settings = JSON.parse(settings);
		}
		if (settings.status) {
			setStatus(settings.status);
		}
		if (settings.offices) {
			setOffices(settings.offices);
		}
		if (settings.tpas) {
			setTpas(settings.tpas);
		}
		if (settings.carriers) {
			setCarriers(settings.carriers);
		}
		if (settings.extraFields) {
			setExtraFields(settings.extraFields);
		}
		if (settings.requiredFields) {
			logDebug("Required fields:", settings.requiredFields);
			setRequiredFields(settings.requiredFields);
		}
	}, [appStore.selectedOrganization?.customSettings]);

	return (
		<div className="border border-black">
			<div className="p-2">
				Status:{" "}
				<input
					type="text"
					className="border w-16 px-1"
					value={name}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
				Color:{" "}
				<input
					type="text"
					className="border w-16 px-1"
					value={color}
					onChange={(e) => {
						setColor(e.target.value);
					}}
				/>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (name && color && !status.find((s) => s.name === name)) {
							setStatus([...status, { name, color }]);
							setColor("");
							setName("");
						}
					}}
				>
					add
				</button>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (name) {
							const index = status.findIndex((s) => s.name === name);
							if (index >= 0) {
								const newStatus = [...status];
								newStatus.splice(index, 1);
								setStatus(newStatus);
								setColor("");
								setName("");
							}
						}
					}}
				>
					remove
				</button>
				<div>Status Preview</div>
				<textarea readOnly className="border w-full h-32" value={JSON.stringify(status)}></textarea>
			</div>
			{/* Office */}
			<div className="p-2">
				Office:{" "}
				<input
					type="text"
					className="border w-16 px-1"
					value={office}
					onChange={(e) => {
						setOffice(e.target.value);
					}}
				/>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (office && !offices.includes(office)) {
							setOffices([...offices, office]);
							setOffice("");
						}
					}}
				>
					add
				</button>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (office) {
							const index = offices.findIndex((s) => s === office);
							if (index >= 0) {
								const newOffices = [...offices];
								newOffices.splice(index, 1);
								setOffices(newOffices);
								setOffice("");
							}
						}
					}}
				>
					remove
				</button>
				<div>Offices Preview</div>
				<textarea readOnly className="border w-full h-32" value={JSON.stringify(offices)}></textarea>
			</div>
			{/* TPA */}
			<div className="p-2">
				TPA:{" "}
				<input
					type="text"
					className="border w-16 px-1"
					value={tpa}
					onChange={(e) => {
						setTpa(e.target.value);
					}}
				/>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (tpa && !tpas.includes(tpa)) {
							setTpas([...tpas, tpa]);
							setTpa("");
						}
					}}
				>
					add
				</button>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (tpa) {
							const index = tpas.findIndex((s) => s === tpa);
							if (index >= 0) {
								const newTPAs = [...tpas];
								newTPAs.splice(index, 1);
								setTpas(newTPAs);
								setTpa("");
							}
						}
					}}
				>
					remove
				</button>
				<div>TPA Preview - (if filled TPA will be combobox otherwise text field)</div>
				<textarea readOnly className="border w-full h-32" value={JSON.stringify(tpas)}></textarea>
			</div>
			{/* Carrier List */}
			<div className="p-2">
				Carrier:{" "}
				<input
					type="text"
					className="border w-16 px-1"
					value={carrier}
					onChange={(e) => {
						setCarrier(e.target.value);
					}}
				/>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (carrier && !carriers.includes(carrier)) {
							setCarriers([...carriers, carrier]);
							setCarrier("");
						}
					}}
				>
					add
				</button>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (carrier) {
							const index = carriers.findIndex((s) => s === carrier);
							if (index >= 0) {
								const newCarriers = [...carriers];
								newCarriers.splice(index, 1);
								setCarriers(newCarriers);
								setCarrier("");
							}
						}
					}}
				>
					remove
				</button>
				<div>Carrier Preview</div>
				<textarea readOnly className="border w-full h-32" value={JSON.stringify(carriers)}></textarea>
			</div>
			{/* Extra fields List */}
			<div className="p-2">
				Extra fields:{" "}
				<input
					type="text"
					className="border w-16 px-1"
					value={extraField}
					onChange={(e) => {
						setExtraField(e.target.value);
					}}
				/>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (extraField && !extraFields.includes(extraField)) {
							setExtraFields([...extraFields, extraField]);
							setExtraField("");
						}
					}}
				>
					add
				</button>
				<button
					className="bg-sky-100 rounded px-1 mx-1"
					onClick={() => {
						if (extraField) {
							const index = extraFields.findIndex((s) => s === extraField);
							if (index >= 0) {
								const newExtraFields = [...extraFields];
								newExtraFields.splice(index, 1);
								setExtraFields(newExtraFields);
								setExtraField("");
							}
						}
					}}
				>
					remove
				</button>
				<div> Extra fields Preview</div>
				<textarea readOnly className="border w-full h-32" value={JSON.stringify(extraFields)}></textarea>
			</div>
			<div className="w-full">
				<div className="m-2">Required fields</div>
				<div className="h-32 overflow-y-auto border border-black m-2">
					{Object.keys(EstimaticsDataKeysAsObject).map((key, index) => {
						const value = EstimaticsDataKeysAsObject[key];
						return (
							<div key={key + requiredFields.includes(key)} className="justify-start items-center gap-4">
								<div className="flex m-2">
									<LassoToggle
										checked={requiredFields.includes(key) || index === 0}
										disabled={index === 0}
										onChange={(v) => {
											if (v) {
												if (!requiredFields.includes(key)) {
													setRequiredFields([...requiredFields, key]);
												}
											} else {
												const index = requiredFields.findIndex((r) => r === key);
												if (index >= 0) {
													const newRequiredFields = [...requiredFields];
													newRequiredFields.splice(index, 1);
													setRequiredFields(newRequiredFields);
												}
											}
										}}
									/>
									<div className="mx-2">{value}</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<button
				className="bg-sky-100 p-2 rounded-xl"
				onClick={async () => {
					appStore.setCustomeSettings(JSON.stringify({ status, offices, tpas, carriers, extraFields, requiredFields }));
					logDebug("Custom settings:", appStore.selectedOrganization?.customSettings);
					const yesNo = window.confirm(
						"this will only set the cusotom settings, not other changes to the organization details. Do you want to save the custom settings?"
					);
					if (yesNo) {
						const newOrg = await RestAPI.fetchAPI("/rest/access/editOrganization", "POST", {
							organization: {
								organizationId: appStore.selectedOrganization!.organizationId,
								customSettings: appStore.selectedOrganization!.customSettings,
							},
						});
						await appStore.loadOrganizations(true);
					}
				}}
			>
				Save Custom Settings
			</button>
		</div>
	);
});

const LassoMVPOrganizationSettingsPage = observer(() => {
	const { organizationId } = useParams(); // 'param' matches the parameter name in the route
	const [fetching, setFetching] = useState(false);
	const [externalSubmissionURL, setExternalSubmissionURL] = useState("");

	const appStore = getAppStore();
	const navigate = useNavigate();
	const setSelectedOrganization = async () => {
		setFetching(true);
		try {
			await appStore.loadOrganizations();
			const org = appStore.organizations.find((o) => o.organizationId === organizationId);
			if (org) {
				appStore.setSelectedOrganization(org);
				appStore.setBreadcrumbPath(["Organizations", org.organizationName]);
			}
		} catch (err) {}
		setFetching(false);
	};
	useEffect(() => {
		if (organizationId === "new") {
			appStore.setBreadcrumbPath(["Organizations", "New Organization"]);
			appStore.setNewOrganization();
		} else {
			setSelectedOrganization();
		}
	}, []);

	useEffect(() => {
		const fetchIt = async () => {
			while (appStore.selectedOrganization?.organizationId === undefined) {
				await SharedUtils.sleep(1000);
			}
			const externalSubmissionURL = await RestAPI.fetchAPI("/rest/access/getExternalSubmissionURL", "GET", {
				organizationId: appStore.selectedOrganization?.organizationId,
				origin: location.origin,
			});
			setExternalSubmissionURL(externalSubmissionURL.url);
			logDebug("External submission URL:", externalSubmissionURL.url);
		};

		fetchIt();
	}, []);

	const onCreate = async () => {
		let newOrg: IOrganizationCreateObject | null = null;
		setFetching(true);
		try {
			if (organizationId === "new") {
				logDebug("Creating new organization", appStore.selectedOrganization?.customSettings);
				newOrg = await RestAPI.fetchAPI("/rest/access/createOrganization", "POST", {
					organization: {
						customSettings: appStore.selectedOrganization!.customSettings,
						aiModels: appStore.selectedOrganization!.aiModels,
						organizationAddress: "",
						organizationLogo: appStore.selectedOrganization!.organizationLogo,
						organizationName: appStore.selectedOrganization!.organizationName,
						organizationDescription: appStore.selectedOrganization!.organizationDescription,
					} as IOrganizationCreateObject,
					users: appStore.selectedOrganization?.users,
					allowedDomain: appStore.selectedOrganization!.allowedDomain,
				});
				await appStore.loadOrganizations(true);
				logDebug("Organization:", newOrg);
			} else {
				alert("this will only add new users to the organization and save the logo without changing other organization's details");
				newOrg = await RestAPI.fetchAPI("/rest/access/editOrganization", "POST", {
					organization: {
						organizationId: appStore.selectedOrganization!.organizationId,
						customSettings: appStore.selectedOrganization!.customSettings,
						organizationLogo: appStore.selectedOrganization!.organizationLogo,
					},
					users: appStore.selectedOrganization?.users,
					// allowedDomain: appStore.selectedOrganization!.allowedDomain
				});
				await appStore.loadOrganizations(true);
			}
		} catch (err) {
			logError("Error creating organization", err);
		}
		setFetching(false);
		// TODO :: make sure there was no errors
		if (newOrg) {
			navigate("/");
		} else {
			// TODO :: show error message
			alert("Error creating organization");
		}
	};

	return (
		<>
			{appStore.selectedOrganization && (
				<div>
					<div className="flex w-full justify-center">
						<div>
							{externalSubmissionURL && (
								<div
									className="btn"
									onClick={() => {
										navigator.clipboard.writeText(externalSubmissionURL);
										alert("Submission URL copied to clipboard");
									}}
								>
									{" "}
									Copy Submission URL
								</div>
							)}
							<LassoMVPUserTable />
						</div>
						<div className="ml-8">
							<LassoOrganizationSettings startInEditMode={organizationId === "new"} />
						</div>
						<div>
							<LassoMVPOrganizationCustomization />
						</div>
					</div>
					<div className="h-32"></div>
					<div className="fixed w-full h-[72px] bg-white border-t border-gray-200 flex-col justify-start items-center inline-flex  bottom-0 left-0">
						<div className="self-stretch h-[72px] px-8 justify-between items-center inline-flex">
							<LassoSimpleButtonFull caption="Save changes" onClick={onCreate} />
							<LassoSimpleButtonFull
								caption="Cancel & Back"
								customButtonStyle={{ backgroundColor: "white", border: "1px solid #EAECF0" }}
								customTextStyle={{ color: "#344054" }}
							/>
						</div>
					</div>
				</div>
			)}
			{fetching && <LoadingAnimation />}
		</>
	);
});

export default LassoMVPOrganizationSettingsPage;
