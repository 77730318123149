import TopMostPortal from '../../../components/ui/utils/Portals';
import { getEstimaticsCurrentProjectStore } from '../../../data/DataStores';
import { logDebug } from '../../../shared/logger';
import GenericWizardLeftBar from '../../CreatorTools/MappingTool/components/GenericWizardLeftBar';
import GenericWizardRightBar from '../../CreatorTools/MappingTool/components/GenericWizardRightBar';
import { NewProjectWizardStep1 } from '../NewProjectWizard/NewProjectWizardStep1';
import { NewProjectWizardStep2 } from '../NewProjectWizard/NewProjectWizardStep2';
import { NewProjectWizardStep3 } from '../NewProjectWizard/NewProjectWizardStep3';

const NewProjectModal = () => {
	const steps = [NewProjectWizardStep1, NewProjectWizardStep2, NewProjectWizardStep3];

	// const mappingToolStore = getEstimaticsCurrentProjectStore();
	const newProjectStore = getEstimaticsCurrentProjectStore();
	return <TopMostPortal onBackgroundClick={() => {
		const result = window.confirm("Are you sure you want to close? You will lose all unsaved data.");
		if (result) {
			newProjectStore.wizardStep = -1;
		}
	}}>
		<div className='w-full h-full justify-end flex'>
			<div className='w-[960px]  bg-red-100 flex'>
				<GenericWizardLeftBar manager={newProjectStore} />
				<GenericWizardRightBar manager={newProjectStore} steps={steps} />
			</div>
		</div>
	</TopMostPortal>
};

export default NewProjectModal;