import { observer } from "mobx-react";
import { EstimaticsFileTypes, getEstimaticsCurrentProjectStore, getUserStore } from "../../../data/DataStores";
import RestAPI from "../../../utils/restAPI";
import { AttachmentFile } from "../../../shared/Azure";
import { logDebug } from "../../../shared/logger";
import { getHumanReadableSize } from "../NewProjectWizard/NewProjectWizardStep2";
import { useEffect, useRef, useState } from "react";
import SharedUtils from "../../../shared/SharedUtils";
import Analytics from "../../../utils/Analytics";

export const getImageForFile = (file: AttachmentFile) => {
	// logDebug('getImageForFile', file.originalname, file.mime);
	if (!file.remotePath) return "/assets/estimatics/file-icon.png";
	if (!file.mime) {
		if (file.originalname.endsWith(".svg")) {
			// TODO :: check for svg mime type on the server
			return RestAPI.getStorageFileUrl(file.remotePath!);
		}
		return "/assets/estimatics/file-04.svg";
	}
	switch (file.mime) {
		case "image/jpeg":
		case "image/jpg":
		case "image/png":
		case "image/gif":
		case "image/*":
			return RestAPI.getStorageFileUrl(file.remotePath!);
	}
	return "/assets/ui/file-04.svg";
};

export const UploadFilesCard: React.FC<{ fileType: EstimaticsFileTypes; height?: number }> = ({ fileType, height }) => {
	if (getUserStore().isUserReadOnly) return null;
	const uploadFileInputRef = useRef<HTMLInputElement>(null);
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const accept = fileType === EstimaticsFileTypes.image ? "image/*" : "*/*";
	const [inputKey, setInputKey] = useState(0);
	const handleDragOver = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		const files = Array.from(e.dataTransfer.files) as File[];
		currentEstimateStore.showLoadingAnimation = true;
		try {
			await RestAPI.uploadFiles(files, fileType, currentEstimateStore);
		} catch (err) {}
		currentEstimateStore.showLoadingAnimation = false;
		currentEstimateStore.uploadEstimate("files");
	};
	return (
		<div
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			style={{
				height: height || 272,
			}}
			className={`w-[308px] hover:bg-sky-50 px-6 py-4 bg-white rounded-2xl  border border-gray-300 flex-col justify-center items-center gap-1 inline-flex cursor-pointer`}
			onClick={() => {
				uploadFileInputRef.current!.click();
			}}
		>
			<div className="self-stretch h-[108px] flex-col justify-start items-center gap-3 flex">
				<div className="w-[70.97px] h-[72px] pl-[19.15px] pr-[19.82px] pt-[20.50px] pb-[19.50px] bg-gray-100 hover:bg-blue-50 rounded-[100px] border-8 border-gray-50 flex-col justify-center items-center flex">
					<img src="/assets/ui/plus-circle.svg" className="w-8 h-8 absolute flex-col justify-start items-start flex" />
				</div>
				<div className="self-stretch h-6 flex-col justify-start items-center gap-1 flex">
					<div className="self-stretch justify-center items-start gap-1 inline-flex">
						<div className="justify-center items-center gap-2 flex">
							<div className="justify-start items-center gap-2 flex">
								<div className="text-blue-900 text-base font-semibold font-['Hanken Grotesk'] leading-normal">
									Click to upload
								</div>
							</div>
						</div>
						<div className="text-slate-600 text-base font-normal font-['Hanken Grotesk'] leading-normal">or drag and drop</div>
					</div>
				</div>
			</div>
			<input
				key={inputKey}
				ref={uploadFileInputRef}
				type="file"
				accept={accept}
				className="hidden"
				multiple={true}
				onChange={async (e) => {
					const files = uploadFileInputRef.current!.files;
					currentEstimateStore.showLoadingAnimation = true;
					try {
						await RestAPI.uploadFiles(files, fileType, currentEstimateStore);
					} catch (err) {}
					currentEstimateStore.showLoadingAnimation = false;
					currentEstimateStore.uploadEstimate("files");
					setInputKey(inputKey + 1);
				}}
			/>
		</div>
	);
};

export const EstimaticsNoneImageFileCard: React.FC<{ file: AttachmentFile }> = observer(({ file }) => {
	const userStore = getUserStore();
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const uploadedByUser = userStore.getOtherUserById(file.uploadBy!);

	const uploadedByString = uploadedByUser ? uploadedByUser.firstName + " " + uploadedByUser.lastName : "Unknown";
	const [uploadedSofar, setUploadedSofar] = useState(0);
	useEffect(() => {
		document.body.style.overflowY = "scroll";
		return () => {
			document.body.style.overflowY = "";
		};
	}, []);

	useEffect(() => {
		if (file.remotePath && file.uploadedSofar === 100) {
			setUploadedSofar(100);
		} else {
			setUploadedSofar((file.uploadedSofar || 0) * 0.9); //  don't complete the download bar until we have remotePath
		}
	}, [file.uploadedSofar, file.remotePath]);
	// TODO :: move to Utils
	function getPercentage(): string {
		return `${(uploadedSofar || 0).toFixed(0)}%`;
	}

	//
	return (
		<div
			className={`${
				uploadedSofar >= 0 ? " bg-white border-gray-200 text-slate-600" : "bg-[#FFFBFA] border-red-300 text-red-700"
			} w-[314px] rounded-2xl border `}
		>
			<div className="flex-col justify-centerflex">
				<div
					className="h-24 bg-gray-50 border-b border-gray-200 rounded-t-2xl justify-center items-center flex btn"
					onClick={() => {
						Analytics.Instance.track("EstimaticsTicFileClicked");
						window.open(RestAPI.getStorageFileUrl(file.remotePath!), "_blank");
					}}
				>
					{uploadedSofar < 0 && <div className="w-[234px] h-5 justify-center items-center gap-3 inline-flex">Upload failed</div>}
					{uploadedSofar >= 0 && uploadedSofar < 100 && (
						<div className="w-[234px] h-5 justify-start items-center gap-3 inline-flex">
							<div className="w-full bg-gray-200 rounded flex-col justify-center items-start inline-flex">
								<div className="h-2 bg-blue-600 rounded " style={{ width: getPercentage() }} />
							</div>
							<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
								{getPercentage()}
							</div>
						</div>
					)}
					{uploadedSofar === 100 && (
						<div className="relative font-medium font-['Hanken Grotesk'] ">
							<img src="/assets/ui/file-04.svg" className="w-16 h-16 relative flex-col justify-start items-start flex" />
							<div className="absolute bg-[#1849A9] text-white w-8 h-5 text-sm top-7 right-[11px] px-0.5 flex justify-center">
								<div>{SharedUtils.getFileExtensionUpper3Max(file.originalname)}</div>
							</div>
						</div>
					)}
				</div>
				<div className="px-4 pt-2 pb-4 relative">
					{!getUserStore().isUserReadOnly && (
						<div
							className="w-9 h-9 bg-red-50 rounded-lg border border-red-50 flex justify-center items-center absolute right-2 btn"
							onClick={(e) => {
								e.stopPropagation();
								Analytics.Instance.track("DeleteEstimaticsFile");
								currentEstimateStore.updateEstimaticsDataFileDeleted(file.uploadId!, true, true, true);
							}}
						>
							<img src="/assets/ui/trash-dark.svg" className="w-6 h-6 relative" />
						</div>
					)}
					<div className="self-stretch text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">File name:</div>
					{/* using dangerouslySetInnerHTML to avoid break the line on spaces */}
					<div
						className="w-64 max-w-64 h-12 two-line-ellipsis pr-4 text-gray-900 text-base font-medium font-['Hanken Grotesk']"
						dangerouslySetInnerHTML={{ __html: file.originalname.replaceAll("-", "‑").replaceAll(" ", "&nbsp;") }}
					/>
					<div className="h-[76px] flex-col justify-center items-start gap-2 flex text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">
						<div>Size: {getHumanReadableSize(file.size)}</div>
						{uploadedSofar >= 0 ? (
							<>
								<div>Uploaded on: {file.uploadDate}</div>
								<div>Uploaded by: {uploadedByString}</div>
							</>
						) : (
							<>
								<div>&nbsp;</div>
								<div>&nbsp;</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

const EstimaticsTicSheetsPanel: React.FC<{}> = observer(({}) => {
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const tics = currentEstimateStore.estimaticsData.files.filter((f) => !f.deleted && f.fileType === EstimaticsFileTypes.tic);
	const uploadFileInputRef = useRef<HTMLInputElement>(null);
	//'*/*'
	return (
		<div className="flex flex-wrap gap-5 justify-left p-8">
			<UploadFilesCard fileType={EstimaticsFileTypes.tic} />
			{tics.map((file, index) => (
				<EstimaticsNoneImageFileCard key={index} file={file} />
			))}
		</div>
	);
});

export default EstimaticsTicSheetsPanel;
