import { observer } from "mobx-react";
import { getEstimaticsCurrentProjectStore, getUserStore } from "../../../data/DataStores";
import React, { createRef, useEffect, useState } from "react";
import { logDebug } from "../../../shared/logger";
import Analytics from "../../../utils/Analytics";
import { setTime } from "react-datepicker/dist/date_utils";

const EstimaticsNotesPanel: React.FC<{}> = observer(({}) => {
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const [defaultNoteValues, setDefaultNoteValues] = useState<{ [k: number]: string }>({});
	const [forceRecalculate, setForceRecalculate] = useState(0);
	const inputRef = createRef<any>();

	useEffect(() => {
		document.body.style.overflowY = "scroll";
		return () => {
			document.body.style.overflowY = "";
		};
	}, []);

	function handleInput() {
		if (inputRef.current) {
			// logDebug('handleInput', `${inputRef.current.scrollHeight}px`);
			inputRef.current.style.height = "auto";
			inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
			inputRef.current.focus();
		} else {
			// logDebug('handleInput', 'inputRef.current is null');
		}
	}

	useEffect(() => {
		handleInput();
	}, [forceRecalculate]);

	return (
		<div className="mt-8 w-full min-h-[250px] min-w-[650px] bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start inline-flex">
			<div className="self-stretch h-[101px] pl-4 pr-6 pt-6 pb-8 bg-white border-b rounded-xl border-gray-200 flex-col justify-start items-start gap-4 flex">
				<div className="self-stretch justify-between items-center inline-flex">
					<div className="w-[480px] flex-col justify-start items-start gap-0.5 inline-flex">
						<div className="self-stretch h-[45px] flex-col justify-start items-start gap-1.5 flex">
							<div className="self-stretch px-3.5 py-2.5  justify-start items-center gap-2 inline-flex">
								<div className="grow shrink basis-0 h-[25px] justify-start items-center gap-2 flex">
									<div className="text-gray-900 text-lg font-semibold font-['Hanken Grotesk'] leading-[25.20px]">
										Internal notes
									</div>
								</div>
							</div>
						</div>
					</div>
					{!getUserStore().isUserReadOnly && (
						<div className="self-stretch  justify-end items-center inline-flex">
							<div className="px-4 py-2.5 bg-sky-50 rounded-lg border border-sky-50 justify-center items-center gap-2 flex btn">
								<div
									className="justify-start items-center gap-2 flex "
									onClick={() => {
										Analytics.Instance.track("EstimaticsNoteAddClicked");
										currentEstimateStore.pushNoteFront("");
									}}
								>
									<img className="w-5 h-5 relative" src="/assets/ui/plus.svg" />
									<div className="text-blue-700 text-sm font-semibold font-['Hanken Grotesk'] leading-tight">
										Add note
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{currentEstimateStore.estimaticsData.notes.map((note, index) => {
				return (
					<div className="w-full relative ">
						<div key={index} className="self-stretch min-h-[72px] flex-col justify-start items-start gap-6 flex">
							<div className=" self-stretch min-h-[72px] pl-6 pr-4 bg-white border-l border-r border-b border-gray-200 justify-start items-center gap-6 inline-flex">
								{note && (
									<div className="w-[80%] ">
										<div
											className=" whitespace-pre-line py-2 basis-0 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] leading-tight cursor-pointer"
											onClick={() => {
												if (getUserStore().isUserReadOnly) return;
												setDefaultNoteValues({ ...defaultNoteValues, [index]: note });
												currentEstimateStore.setNote(index, "");
												setForceRecalculate((v) => v + 1);
												// setTimeout(() => {
												// 	handleInput();
												// }, 1000);
											}}
										>
											{note}
										</div>
									</div>
								)}
								{!note && (
									<>
										<textarea
											ref={inputRef}
											className="my-4 min-h-16 scrollbar-thin focus:outline-none  max-w-[80%] h-auto grow shrink basis-0 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] border rounded p-1 leading-tight"
											placeholder="Enter note"
											onKeyDown={(e) => {
												handleInput();
											}}
											defaultValue={defaultNoteValues[index] ?? ""}
											onBlur={(e) => {
												if (e.target.value) {
													setTimeout(() => {
														currentEstimateStore.setNote(index, e.target.value);
														if (e.target.value !== defaultNoteValues[index]) {
															currentEstimateStore.uploadEstimate("notes", true);
														}
													}, 100);
												}
												setDefaultNoteValues({});
											}}
										/>
										<div className="absolute bottom-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											Click anywhere outside this field to save changes
										</div>
									</>
								)}
								{/* <div className="grow shrink basis-0 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] underline leading-tight">https://www.globaltechinsights.com/articles/breakthrough-quantum-computing-research</div> */}
							</div>
						</div>
						{!getUserStore().isUserReadOnly && (
							<>
								<div
									className={` absolute right-[70px] top-4  p-2  rounded-lg border border-red-50 ${note ? "btn" : ""}`}
									onClick={() => {
										if (!note) return;
										Analytics.Instance.track("EditEstimaticsNote");
										setDefaultNoteValues({ ...defaultNoteValues, [index]: note });
										currentEstimateStore.setNote(index, "");
										setForceRecalculate((v) => v + 1);
									}}
								>
									<img className="w-5 h-5    relative " src={`/assets/ui/edit-03${note ? "" : "-disabled"}.svg`} />
								</div>
								<div
									className=" absolute right-6 top-4 bg-red-50 p-2  rounded-lg border border-red-50 btn"
									onClick={() => {
										Analytics.Instance.track("DeleteEstimaticsNote");
										currentEstimateStore.removeNote(index);
										currentEstimateStore.uploadEstimate("notes", true);
									}}
								>
									<img className="w-5 h-5    relative cursor-pointer" src="/assets/ui/trash-02.svg" />
								</div>
							</>
						)}{" "}
					</div>
				);

				// return <div className='w-full relative bg-blue-100'>
				// 	<div key={index} className="self-stretch  bg-green-100 flex-col justify-start items-start gap-6 flex">
				// 		<div className="self-stretch pl-6 pr-4 bg-red-100  border-l border-r border-b border-gray-200 justify-start items-center gap-6 inline-flex">
				// 			{
				// 				note &&
				// 				<div className='w-[90%] '>

				// 					<div className="grow shrink basis-0 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] leading-tight cursor-pointer"
				// 						onClick={() => {
				// 							setDefaultNoteValues({ ...defaultNoteValues, [index]: note });
				// 							currentEstimateStore.setNote(index, '');
				// 						}}
				// 					>
				// 						{note}
				// 					</div>
				// 				</div>
				// 			}
				// 			{
				// 				!note &&
				// 				<input
				// 					ref={inputRef}
				// 					type='text'
				// 					id={`editable-note-index-${index}`}
				// 					className="focus:outline-none max-w-[87%] grow shrink basis-0 text-gray-900 text-sm font-normal font-['Hanken Grotesk'] border rounded p-1 leading-tight"
				// 					placeholder='Enter note'
				// 					defaultValue={defaultNoteValues[index] ?? ''}
				// 					onKeyDown={(e) => {
				// 						if (e.key === 'Enter') {
				// 							inputRef.current?.blur();
				// 						}
				// 					}}
				// 					onBlur={(e) => {
				// 						if (e.target.value) {
				// 							currentEstimateStore.setNote(index, e.target.value);
				// 							currentEstimateStore.uploadEstimate('notes');
				// 						}
				// 					}} />
				// 			}
				// 		</div>
				// 	</div>
				// 	<div className='absolute right-6 top-6'>
				// 		<img className="w-5 h-5 relative cursor-pointer" src='/assets/ui/trash-02.svg' onClick={() => {
				// 			currentEstimateStore.removeNote(index);
				// 			currentEstimateStore.uploadEstimate('links', true);
				// 		}} />
				// 	</div>
				// </div>
			})}
		</div>
	);

	return (
		<div>
			{currentEstimateStore.estimaticsData.notes.map((note, index) => {
				return (
					<div key={index} className="flex items-center justify-start  bg-gray-200 m-4">
						{note}
					</div>
				);
			})}
		</div>
	);
});

export default EstimaticsNotesPanel;
