import LassoUserImage from "../../components/ui/lasso/LassoUserImage";
import "./LassoTopBar_Estimatics.scss";
import { getAppStore, getUserStore } from "../../data/DataStores";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { appFeatures } from "../../data/AppFeatures";
import RestAPI from "../../utils/restAPI";

const LassoTopBar: React.FC = observer(() => {
	const appStore = getAppStore();
	const navigate = useNavigate();
	useEffect(() => {
		if (location.href.includes("/template") && location.href.includes("localhost")) {
			return;
		}
		if (location.href.includes("disable-dev-private")) {
			return;
		}
		if (!appStore.breadcrumbPath) {
			return;
		}
		if (appFeatures.isEstimatics) {
			if (appStore.breadcrumbPath.length === 0) {
				navigate("/");
			}
		} else {
			if (appStore.breadcrumbPath.length === 0) {
				navigate("/");
			}
		}
	}, [appStore.breadcrumbPath]);

	let logoSource = appFeatures.isEstimatics ? "/assets/estimatics/Logomark.png" : "/assets/ui/Logomark.svg";
	if (appFeatures.isEstimatics && getUserStore().getExternalSubmissionUser()) {
		if (getUserStore().getExternalSubmissionUser().organizationLogo) {
			logoSource = RestAPI.getStorageFileUrl(getUserStore().getExternalSubmissionUser().organizationLogo);
		}
	}

	return (
		<div className="bar-frame">
			<div className="w-[60px]"></div>
			{appFeatures.isEstimatics ? (
				<img
					className="w-[73px] btn"
					src={logoSource}
					alt="Restoration"
					onClick={() => {
						appStore.setBreadcrumbPath([]);
					}}
				/>
			) : (
				<img src={logoSource} width="32" height="32" alt="Lasso" />
			)}
			{!appFeatures.isEstimatics && <div className="lasso ml-2">Lasso</div>}
			<div className="breadcrumbs flex items-center select-none">
				<img
					src="/assets/ui/home-line.svg"
					width="24"
					alt="Lasso"
					className="btn"
					onClick={() => {
						appStore.setBreadcrumbPath([]);
					}}
				/>
				{["Dashboard", ...appStore.breadcrumbPathSafe].map((path, index) => {
					return (
						<React.Fragment key={index}>
							{index > 0 && <img src="/assets/ui/chevron-right.svg" width="20" alt="Lasso" />}
							<div
								className="dashboard"
								onClick={() => {
									if (index < appStore.breadcrumbPathSafe.length) {
										appStore.setBreadcrumbPath(appStore.breadcrumbPathSafe.slice(0, index));
									}
								}}
								style={{
									cursor: index < appStore.breadcrumbPathSafe.length ? "pointer" : "auto",
									pointerEvents: index < appStore.breadcrumbPathSafe.length ? "auto" : "none",
								}}
							>
								{path}
							</div>
						</React.Fragment>
					);
				})}
			</div>
			<div className="right-bar mr-8">
				{/* <img src='/assets/ui/settings-01.svg' width='24' alt='Lasso' /> */}
				{!appFeatures.isEstimatics && <img src="/assets/ui/bell-01.svg" width="24 " alt="Lasso" />}
				<LassoUserImage />
				{getUserStore().isUserReadOnly && <div className="ml-4 text-sm">Read Only</div>}
			</div>
		</div>
	);
});

export default LassoTopBar;
