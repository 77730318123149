import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { EstimaticsFileTypes, getEstimaticsCurrentProjectStore, getUserStore } from "../../../data/DataStores";
import { UploadFilesCard, getImageForFile } from "./EstimaticsTicSheetsPanel";
import ImageWithPlaceholder from "../../../components/ui/utils/ImageWithPlaceholder";
import { getHumanReadableSize } from "../NewProjectWizard/NewProjectWizardStep2";
import TopMostPortal from "../../../components/ui/utils/Portals";
import { logDebug } from "../../../shared/logger";
import { AttachmentFile } from "../../../shared/Azure";
import SharedUtils from "../../../shared/SharedUtils";
import Analytics from "../../../utils/Analytics";

const EstimaticsImageFileCard: React.FC<{ file: AttachmentFile; onImageClicked: () => void }> = observer(({ file, onImageClicked }) => {
	const userStore = getUserStore();
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const [uploadedSofar, setUploadedSofar] = useState(0);
	const uploadedByUser = userStore.getOtherUserById(file.uploadBy!);
	const uploadedByString = uploadedByUser ? uploadedByUser.firstName + " " + uploadedByUser.lastName : "Unknown";
	useEffect(() => {
		document.body.style.overflowY = "scroll";
		return () => {
			document.body.style.overflowY = "";
		};
	}, []);

	useEffect(() => {
		if (file.remotePath && file.uploadedSofar === 100) {
			setUploadedSofar(100);
		} else {
			setUploadedSofar((file.uploadedSofar || 0) * 0.9); //  don't complete the download bar until we have remotePath
		}
	}, [file.uploadedSofar, file.remotePath]);
	// TODO :: move to Utils
	function getPercentage(): string {
		return `${(uploadedSofar || 0).toFixed(0)}%`;
	}

	return (
		<div
			className={`w-[314px] rounded-2xl border ${
				uploadedSofar >= 0 ? " bg-white border-gray-200 text-slate-600" : "bg-[#FFFBFA] border-red-300 text-red-700"
			} `}
		>
			<div className="flex-col justify-centerflex">
				<div
					className=" bg-gray-50 border-b border-gray-200 rounded-t-2xl justify-center items-center flex btn"
					onClick={onImageClicked}
				>
					{uploadedSofar < 0 && (
						<div className="h-[213px] w-[234px] justify-center items-center gap-3 inline-flex">Upload failed</div>
					)}
					{uploadedSofar >= 0 && uploadedSofar < 100 && (
						<div className="h-[213px] w-[234px] h-5 justify-start items-center gap-3 inline-flex">
							<div className="w-full bg-gray-200 rounded flex-col justify-center items-start inline-flex">
								<div className="h-2 bg-blue-600 rounded " style={{ width: getPercentage() }} />
							</div>
							<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
								{getPercentage()}
							</div>
						</div>
					)}
					{uploadedSofar === 100 && (
						<div className="h-[213px] bg-gray-50 border-b border-gray-200 rounded-t-2xl justify-center items-center flex ">
							<div className=" w-[308px] h-[213px] bg-gray-50 flex items-center justify-center overflow-hidden">
								<ImageWithPlaceholder
									className="max-w-full max-h-full"
									src={getImageForFile(file)}
									alt="file"
									placeholder="/assets/estimatics/loading-animation-1.gif"
								/>
							</div>
						</div>
					)}
				</div>
				<div className="px-4 pt-2 pb-4 relative">
					{!getUserStore().isUserReadOnly && (
						<div
							className="w-9 h-9 bg-red-50 rounded-lg border border-red-50 flex justify-center items-center absolute right-2 btn"
							onClick={(e) => {
								Analytics.Instance.track("DeleteEstimaticsFile");
								e.stopPropagation();
								currentEstimateStore.updateEstimaticsDataFileDeleted(file.uploadId!, true, true, true);
							}}
						>
							<img src="/assets/ui/trash-dark.svg" className="w-6 h-6 relative" />
						</div>
					)}
					<div className="self-stretch text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight ">File name:</div>
					{/* using dangerouslySetInnerHTML to avoid break the line on spaces */}
					<div
						className="w-64 max-w-64 h-12 two-line-ellipsis pr-4 text-gray-900 text-base font-medium font-['Hanken Grotesk']"
						dangerouslySetInnerHTML={{ __html: file.originalname.replaceAll("-", "‑").replaceAll(" ", "&nbsp;") }}
					/>

					<div className="h-[76px] flex-col justify-center items-start gap-2 flex text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight">
						<div>Size: {getHumanReadableSize(file.size)}</div>
						{uploadedSofar >= 0 ? (
							<>
								<div>Uploaded on: {file.uploadDate}</div>
								<div>Uploaded by: {uploadedByString}</div>
							</>
						) : (
							<>
								<div>&nbsp;</div>
								<div>&nbsp;</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

const EstimaticsImagesPanel: React.FC<{}> = observer(({}) => {
	const currentEstimateStore = getEstimaticsCurrentProjectStore();
	const images = currentEstimateStore.estimaticsData.files.filter((f) => !f.deleted && f.fileType === EstimaticsFileTypes.image);
	const [showGalleryImage, setShowGalleryImage] = useState(-1);
	const [scrollXValue, setScrollXValue] = useState(0);
	const imagesContainerRef = useRef<HTMLDivElement>(null);

	const scrollDirection = (direction: "left" | "right") => {
		let newValue = scrollXValue + (direction === "left" ? 100 : -100);
		logDebug("scrollDirection", direction, scrollXValue, imagesContainerRef.current?.scrollWidth);
		if (newValue > 0) {
			newValue = 0;
		}
		if (newValue < -imagesContainerRef.current!.scrollWidth + imagesContainerRef.current!.clientWidth) {
			newValue = -imagesContainerRef.current!.scrollWidth + imagesContainerRef.current!.clientWidth;
		}
		setScrollXValue(newValue);
	};

	useEffect(() => {
		if (showGalleryImage >= 0) {
			logDebug("EstimaticsImagesPanel", getImageForFile(images[showGalleryImage]));
		}
	}, [images, showGalleryImage]);

	return (
		<div className="flex flex-wrap gap-5 justify-left p-8">
			<UploadFilesCard fileType={EstimaticsFileTypes.image} height={389} />
			{
				images.map((file, index) => (
					<EstimaticsImageFileCard
						key={index}
						file={file}
						onImageClicked={() => {
							Analytics.Instance.track("ShowGalleryImage");
							setShowGalleryImage(index);
						}}
					/>
				))
				// 	<div
				// 		key={index}
				// 		className="relative w-[308px] h-[293px]  border flex flex-col justify-center text-white text-sm cursor-pointer"
				// 		onClick={() => {
				// 			setShowGalleryImage(index);
				// 		}}
				// 	>
				// 		<div className=" w-[308px] h-[213px] bg-gray-50 flex items-center justify-center overflow-hidden">
				// 			<ImageWithPlaceholder className="max-w-full max-h-full" src={getImageForFile(file)} alt='file' placeholder='/assets/estimatics/loading-animation.gif' />
				// 		</div>
				// 		<div className='h-[80px] text-slate-600 '>
				// 			<div>{file.originalname}</div>
				// 			<div>{getHumanReadableSize(file.size)}</div>
				// 			<div>{file.uploadDate}</div>
				// 			<div>{file.uploadBy}</div>
				// 		</div>
				// 		<div className='absolute top-1 right-1 cursor-pointer' onClick={(e) => {
				// 			e.stopPropagation();
				// 			currentEstimateStore.updateEstimaticsDataFileDeleted(file.uploadId!, true, true);

				// 		}}>
				// 			<img src='/assets/ui/trash-02.svg' className='w-6 h-6' />
				// 		</div>
				// 	</div>
				// ))
			}
			{showGalleryImage >= 0 && (
				<TopMostPortal>
					<div className="h-[80%] w-[80%] flex flex-col bg-white">
						<div className="h-32  flex items-center justify-center relative">
							<img
								src="/assets/ui/x.svg"
								className="absolute top-4 right-4 w-4 h-4 cursor-pointer"
								onClick={() => {
									setShowGalleryImage(-1);
								}}
							/>
							<p>{images[showGalleryImage].originalname}</p>
						</div>

						<div className="flex h-full items-center justify-center  overflow-hidden bg-gray-200">
							<div
								className="  flex items-center justify-center overflow-hidden"
								style={{
									width: "100%",
									height: "100%",
									backgroundSize: "contain",
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center",
									backgroundImage: `url("${getImageForFile(images[showGalleryImage])}")`,
								}}
							>
								{/* <img className="cover" src={getImageForFile(images[showGalleryImage])} alt="" /> */}
							</div>
						</div>

						<div className="h-48  items-center flex justify-center">
							<div className="w-[60%]   flex items-center justify-between overflow-hidden ">
								<div
									className="h-full flex items-center cursor-pointer select-none pr-8"
									onClick={() => {
										scrollDirection("left");
									}}
								>
									<div className="w-9 h-9 p-2 bg-sky-50 rounded-lg border border-sky-50 justify-center items-center gap-2 inline-flex">
										<img src="/assets/ui/arrow-right.svg" className="w-5 h-5 relative" />
									</div>
								</div>
								<div
									ref={imagesContainerRef}
									className="relative flex overflow-x-hidden overflow-y-hidden h-full items-center space-x-2"
								>
									{images.map((link, index) => (
										<div
											key={index}
											className="w-20 rounded-lg h-20 min-w-20 min-h-20 flex items-center justify-center border btn relative"
											style={{ left: scrollXValue }}
											onClick={() => {
												Analytics.Instance.track("ShowGalleryImage");
												setShowGalleryImage(index);
											}}
										>
											<img
												src={getImageForFile(link)}
												// className={`relative  `}

												alt="gallery-image"
											/>
										</div>
									))}
								</div>
								<div
									className="h-full flex items-center cursor-pointer select-none pl-8"
									onClick={() => {
										scrollDirection("right");
									}}
								>
									<div className="w-9 h-9 p-2 rotate-180 bg-sky-50 rounded-lg border border-sky-50 justify-center items-center gap-2 inline-flex">
										<img src="/assets/ui/arrow-right.svg" className="w-5 h-5 relative" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</TopMostPortal>
			)}
		</div>
	);
});

export default EstimaticsImagesPanel;
