import React, { useEffect, useRef, useState } from "react";
import { getUserStore } from "../../data/DataStores";
import { LassoSimpleButtonFull } from "../../components/ui/utils/LassoButtons";
import { IOrganizationCreateUser } from "../../shared/interfaces";
import SharedUtils, { Roles } from "../../shared/SharedUtils";
import RestAPI from "../../utils/restAPI";
import { logDebug, logError } from "../../shared/logger";
import SecureImage from "../../components/ui/utils/SecureImage";

const LassoMVPUserSettingsModal: React.FC<{
	caption?: string;
	editingUser: IOrganizationCreateUser;
	isNewUser?: boolean;
	update?: (user: IOrganizationCreateUser) => void;
	cancel?: () => void;
	usersForValidation?: IOrganizationCreateUser[];
}> = ({ editingUser, isNewUser, update, cancel, caption, usersForValidation }) => {
	const userStore = useRef(getUserStore());
	const [email, setEmail] = useState(editingUser.email);
	const [allowEmail, setAllowEmail] = useState(!editingUser.email);
	const [firstName, setFirstName] = useState(editingUser.firstName || "");
	const [lastName, setLastName] = useState(editingUser.lastName || "");
	const [photoURL, setPhotoURL] = useState(editingUser.photoURL || "");
	const [role, setRole] = useState(editingUser.role || Roles.USER_ROLE);
	const [inputValid, setInputValid] = useState(false);

	const [loading, setLoading] = useState(false);

	// TODO ::: need to be handled by the caller
	const onUpdate = async () => {
		update &&
			update({
				role: role,
				email,
				firstName,
				lastName,
				photoURL,
			});
	};

	useEffect(() => {
		if (allowEmail) {
			setInputValid(!!email && SharedUtils.testEmail(email));
		} else {
			setInputValid(!!firstName && !!lastName);
		}
	}, [email, firstName, lastName]);

	return (
		<div className="w-[722px] h-[615px] relative bg-white rounded-xl shadow">
			<div className="h-[73px] left-0 top-0 absolute bg-white flex-col justify-start items-center inline-flex">
				<div className="self-stretch px-6 pt-6 bg-white justify-start items-start gap-4 inline-flex">
					<div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
						<div className="self-stretch text-gray-900 text-lg font-semibold font-['Inter'] leading-7">
							{caption || "Edit user"}
						</div>
					</div>
				</div>
				<div className="p-2.5 rounded-lg justify-center items-center inline-flex">
					<div className="w-6 h-6 relative" />
				</div>
				<div className="self-stretch h-5" />
			</div>
			<div className="w-[622px]  left-[50px] top-[104px] absolute">
				<div className="left-[209px] top-0 absolute flex-col justify-start items-start gap-[30px] inline-flex">
					<div className="w-[413px] h-[70px] relative">
						<div className="w-[413px] h-11 left-0 top-[26px] absolute bg-white rounded-lg border border-gray-200" />
						<div className="whitespace-nowrap left-0 top-0 absolute text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
							First name
						</div>
						<input
							onChange={(e) => {
								setFirstName(e.target.value);
							}}
							value={firstName}
							type="text"
							className="left-[14px] top-[36px] w-[380px] border-0 focus:outline-none absolute text-slate-600 text-base font-medium font-['Hanken Grotesk'] leading-normal"
						/>
					</div>
					<div className="h-[70px] relative">
						<div className="w-[413px] h-11 left-0 top-[26px] absolute bg-white rounded-lg border border-gray-200" />
						<div className="whitespace-nowrap left-0 top-0 absolute text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
							Last name
						</div>
						<input
							onChange={(e) => {
								setLastName(e.target.value);
							}}
							value={lastName}
							type="text"
							className="left-[14px] top-[36px] w-[380px] border-0 focus:outline-none absolute text-slate-600 text-base font-medium font-['Hanken Grotesk'] leading-normal"
						/>
					</div>

					{role !== "-" && (
						<div className="h-[70px] relative">
							<div className="w-[413px] h-11 left-0 top-[26px] absolute bg-white rounded-lg border border-gray-200" />
							<div className="whitespace-nowrap left-0 top-0 absolute text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
								User role
							</div>
							<select
								className="left-[14px] top-[36px] w-[380px] border-0 focus:outline-none absolute text-slate-600 text-base font-medium font-['Hanken Grotesk'] leading-normal"
								onChange={(e) => setRole(e.target.value)}
								value={role}
							>
								<option value="admin">Admin</option>
								<option value="user">User</option>
								<option value="readonly-user">Read Only</option>
							</select>
						</div>
					)}
					<div className="h-[70px] relative">
						<div className="left-0 top-0 absolute text-slate-700 text-sm font-medium font-['Hanken Grotesk'] whitespace-nowrap ">{`Email ${
							allowEmail ? "*" : ""
						}`}</div>
						{allowEmail ? (
							<div className="h-[70px] relative">
								<div className="w-[413px] h-11 left-0 top-[26px] absolute bg-white rounded-lg border border-gray-200" />
								<input
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									value={email}
									type="text"
									className="left-[14px] top-[36px] w-[380px] border-0 focus:outline-none absolute text-slate-600 text-base font-medium font-['Hanken Grotesk'] leading-normal"
								/>
							</div>
						) : (
							<div className="w-[413px] h-11 left-0 top-[26px] absolute opacity-50 flex-col justify-start items-start inline-flex">
								<div className="w-[413px] h-11 bg-gray-200 rounded-lg border border-gray-200 flex items-center">
									<div className="text-slate-600 text-base font-medium font-['Hanken Grotesk'] leading-normal pl-2">
										{email}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>

				<div className="w-40 h-40 justify-center items-center gap-2.5 inline-flex relative">
					<div className="w-40 h-40 p-8 bg-gray-100 rounded-[200px]  border-2 border-neutral-50 justify-center items-center flex">
						{/* <img src='/assets/ui/empty-user.svg' className="w-24 h-24  absolute flex-col justify-start items-start flex" /> */}
						<SecureImage
							onUrlChange={(url) => setPhotoURL(url)}
							destination={`users/${userStore.current.user?.uid}/public`}
							w={96}
							h={96}
							className="w-40 h-40 btn "
							defaultImage={
								userStore.current.user?.photoURL
									? RestAPI.getStorageFileUrl(userStore.current.user.photoURL)
									: "/assets/ui/empty-user.svg"
							}
						/>
					</div>
					{/* <div className="w-14 h-14 absolute -right-1 -bottom-1">
					<div className="w-14 h-14   absolute bg-white rounded-full" />
					<div className="p-3.5 left-[4px] top-[4px] absolute bg-white rounded-[100px] shadow border border-gray-300 justify-center items-center gap-2 inline-flex">
						<img src='/assets/ui/camera-02.svg' className="w-5 h-5 relative" />
					</div>
				</div> */}
				</div>
			</div>
			<div className="w-[722px] h-[92px] left-0 top-[516px] absolute">
				<div className="h-[74px] pt-1.5 top-[6px] absolute flex-col justify-center items-center flex w-full">
					<div className="justify-between items-center flex w-full px-4">
						{cancel && (
							<LassoSimpleButtonFull
								caption="Cancel"
								onClick={cancel}
								customButtonStyle={{ backgroundColor: "white", border: "1px solid #EAECF0" }}
								customTextStyle={{ color: "#344054" }}
							/>
						)}
						<LassoSimpleButtonFull
							disabled={!inputValid}
							caption="Confirm changes"
							onClick={() => {
								if (usersForValidation && usersForValidation.find((u) => u.email === email)) {
									alert("User with this email already exists");
									return;
								}
								onUpdate();
							}}
						/>
					</div>
				</div>
				<div className="w-[722px] h-[0px] left-0 top-0 absolute border border-gray-200"></div>
			</div>
		</div>
	);
};

export default LassoMVPUserSettingsModal;
