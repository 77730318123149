import React, { useEffect, useRef, useState } from "react";
import { EstimaticsDataKeys, getEstimaticsCurrentProjectStore, getUserStore, isExternalSubmissionPage } from "../../../data/DataStores";
import { observer } from "mobx-react";
import AddressCompletion from "../../../components/ui/utils/AddressCompletion";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SharedUtils from "../../../shared/SharedUtils";
import { logDebug } from "../../../shared/logger";
import Analytics from "../../../utils/Analytics";
import Select from "react-select";
import { Tooltip } from "react-tooltip";

export const NewProjectWizardStyledInput: React.FC<{
	propertyName: EstimaticsDataKeys | string;
	onChange?: (v: string) => void;
	onValidation?: (v: boolean) => void;
	placeholder: string;
}> = observer(({ propertyName, onChange, placeholder, onValidation }) => {
	const newProjectStore = getEstimaticsCurrentProjectStore();
	function getValue(): string {
		return newProjectStore.getValue(propertyName!);
	}
	function setValue(v: string) {
		newProjectStore.setValue(propertyName!, v);
	}

	return (
		<div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
			<div className="grow shrink basis-0 h-5 justify-start items-center gap-2 flex">
				<input
					value={getValue()}
					onChange={(e) => {
						setValue(e.target.value);
						onChange && onChange(e.target.value);
					}}
					onBlur={() => {
						if (propertyName === "email") {
							onValidation && onValidation(!SharedUtils.testEmail(getValue()));
						} else if (propertyName === "phone") {
							onValidation && onValidation(!SharedUtils.testPhone(getValue()));
						}
					}}
					placeholder={placeholder}
					type="text"
					className="focus:outline-none w-full h-full bg-transparent text-gray-900 text-sm font-normal font-['Hanken Grotesk'] leading-tight"
				/>
			</div>
		</div>
	);
});
export const useIsInputTruncated = (hint?: string) => {
	const ref = useRef<any>(null);
	const [isTruncated, setIsTruncated] = useState(false);

	useEffect(() => {
		const input = (ref.current as HTMLElement)?.querySelector<HTMLElement>('div[class$="-singleValue"]');
		hint && logDebug(hint, "change", input?.parentNode);
		if (input) {
			const el = input as HTMLElement;
			hint && logDebug(hint, "trunk", el.scrollWidth > el.clientWidth);
			setIsTruncated(el.scrollWidth > el.clientWidth);
		}
	}, [(ref.current as HTMLElement)?.querySelector<HTMLElement>('div[class$="-singleValue"]')?.innerText]);

	return { ref, isTruncated };
};

const NewProjectWizardSimpleCombobox: React.FC<{
	options: string[];
	value: string;
	onChange: (val: string) => void;
	title?: string;
	placeholder?: string;
	clearable?: boolean;
	enableTooltip?: boolean;
}> = observer(({ value, onChange, title, options, placeholder, clearable, enableTooltip }) => {
	const { ref: truncatedTextRef, isTruncated } = useIsInputTruncated();
	return (
		<div
			className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex"
			ref={truncatedTextRef}
			data-tooltip-delay-show={500}
			data-tooltip-id={`tooltip-step1-${title}`}
			data-tooltip-content={value}
		>
			{title && <div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">{title}</div>}
			<Select
				styles={{
					control: (baseStyles, state) => ({
						...baseStyles,
						borderRadius: "6px",
						height: "42px",
					}),
				}}
				placeholder={placeholder}
				isClearable={!!clearable}
				className="w-full focus:outline-none "
				value={
					value
						? {
								value,
								label: value,
						  }
						: null
				}
				onChange={(newValue) => onChange(newValue?.value ?? "")}
				options={options.map((option) => ({ value: option, label: option }))}
			/>
			{isTruncated && <Tooltip id={`tooltip-step1-${title}`} />}
		</div>
	);
});

const TruncatedText: React.FC<{ text: string }> = ({ text }) => {
	const { ref, isTruncated } = useIsInputTruncated();

	return (
		<div>
			<div
				//@ts-ignore
				ref={ref}
				style={{
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
					width: "200px",
				}}
			>
				{text}
			</div>
			{isTruncated && <span>...</span>}
		</div>
	);
};

export const NewProjectWizardStep1: React.FC<{
	onNext: (n: number) => void;
	onBack: () => void;
	onCancel: () => void;
}> = observer(({ onNext, onBack, onCancel }) => {
	const userStore = getUserStore();
	const newProjectStore = getEstimaticsCurrentProjectStore();
	const [nextEnabled, setNextEnabled] = React.useState(false);
	const [selectedDate, setSelectedDate] = useState(null);
	const [showEmailError, setShowEmailError] = useState(false);
	const [showPhoneError, setShowPhoneError] = useState(false);
	const handleDateChange = (date: any) => {
		setSelectedDate(date);
	};

	async function generateTestEstimates() {
		for (let i = 0; i < 100; i++) {
			await newProjectStore.testOnlyCreateNewTestEstimateData();
		}
	}
	useEffect(() => {
		// generateTestEstimates();
	}, []);
	useEffect(() => {
		let canItEvenBeEnabled = true;
		const firstPageFields = userStore.requiredFields.filter(
			(field) => field !== "ticSheets" && field !== "photos" && field !== "links" && field !== "notes" && field !== "attachments"
		);
		if (firstPageFields.length > 0) {
			canItEvenBeEnabled = firstPageFields.every((field) => {
				// @ts-ignore
				return newProjectStore.estimaticsData[field] && newProjectStore.estimaticsData[field].trim().length > 0;
			});
		}

		setNextEnabled(
			canItEvenBeEnabled &&
				newProjectStore.estimaticsData.projectName.trim().length > 0 &&
				(!newProjectStore.estimaticsData.email || SharedUtils.testEmail(newProjectStore.estimaticsData.email.trim())) &&
				(!newProjectStore.estimaticsData.phone || SharedUtils.testPhone(newProjectStore.estimaticsData.phone.trim()))
		);
	}, [
		newProjectStore.estimaticsData.projectName,
		newProjectStore.estimaticsData.email,
		newProjectStore.estimaticsData.phone,
		newProjectStore.estimaticsData.propertyAddress,
		newProjectStore.estimaticsData.insuranceCarrier,
		newProjectStore.estimaticsData.claimNumber,
		newProjectStore.estimaticsData.tpa,
		newProjectStore.estimaticsData.dateContacted,
		newProjectStore.estimaticsData.dateOfLoss,
		newProjectStore.estimaticsData.approximateClaim,
		newProjectStore.estimaticsData.typeOfLoss,
		newProjectStore.estimaticsData.category,
		newProjectStore.estimaticsData.office,
		newProjectStore.estimaticsData.estimateReferenceNumber,
		newProjectStore.estimaticsData.requesterName,
	]);

	// return <AddressCompletion className='w-64 border rounded' placeholder="Enter property address" value={newProjectStore.estimaticsData.propertyAddress} onChange={(v) => newProjectStore.estimaticsData.propertyAddress = v} />;
	useEffect(() => {
		if (newProjectStore.estimaticsData.typeOfLoss) {
			const selectedTypeOfLoss = userStore.typeOfLossList.find((e) => e.type === newProjectStore.estimaticsData.typeOfLoss);
			if (selectedTypeOfLoss) {
				newProjectStore.setValue("category", selectedTypeOfLoss.categories?.[0] || "");
			}
		}
	}, [newProjectStore.estimaticsData.typeOfLoss]);

	return (
		<div className="flex justify-between  flex-col h-full">
			<div className="w-full   h-full  flex-col justify-start items-start inline-flex overflow-y-scroll scrollbar-thin">
				<div className="  mt-[34px] w-full grow shrink basis-0 px-6 pt-6 flex-col justify-start items-start flex ">
					<div className=" self-stretch  flex-col justify-start items-start gap-8 flex">
						<div className="self-stretch justify-start items-start gap-2.5 inline-flex">
							<div className="grow shrink basis-0 text-gray-900 text-lg font-normal font-['Hanken Grotesk'] leading-7">
								Project Details
							</div>
						</div>
						<div className="self-stretch h-[322.44px] flex-col justify-start items-start gap-6 flex">
							<div className="self-stretch px-2 border-l-2 border-blue-600 justify-start items-start inline-flex">
								<div className="text-slate-700 text-sm font-bold font-['Hanken Grotesk'] leading-tight">Project info</div>
							</div>
							<div className="self-stretch h-[278.44px] flex-col justify-start items-start gap-2 flex">
								<div className="self-stretch justify-start items-start gap-4 inline-flex">
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Project name *
											</div>
											<NewProjectWizardStyledInput propertyName={"projectName"} placeholder="Enter project name" />
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Estimate reference number{" "}
												{userStore.requiredFields.includes("estimateReferenceNumber") && "*"}
											</div>
											<NewProjectWizardStyledInput
												propertyName={"estimateReferenceNumber"}
												placeholder="Enter estimate reference number"
											/>
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
								</div>
								<div className="self-stretch justify-start items-start gap-4 inline-flex">
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Requester name {userStore.requiredFields.includes("requesterName") && "*"}
											</div>
											<NewProjectWizardStyledInput
												propertyName={"requesterName"}
												placeholder="Enter requester name"
											/>
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Email {userStore.requiredFields.includes("email") && "*"}
											</div>
											<NewProjectWizardStyledInput
												propertyName={"email"}
												placeholder="Enter email"
												onValidation={(val) => setShowEmailError(val)}
											/>
										</div>
										{showEmailError && (
											<div className="self-stretch  text-red-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
												Please enter a valid email address.
											</div>
										)}
									</div>
								</div>
								<div className="self-stretch justify-start items-start gap-4 inline-flex">
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Phone {userStore.requiredFields.includes("phone") && "*"}
											</div>
											<NewProjectWizardStyledInput
												propertyName={"phone"}
												placeholder="Enter phone"
												onValidation={(val) => {
													setShowPhoneError(val);
												}}
											/>
										</div>
										{showPhoneError && (
											<div className="self-stretch  text-red-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
												Please enter a valid phone number.
											</div>
										)}
									</div>
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Property address {userStore.requiredFields.includes("propertyAddress") && "*"}
											</div>
											<AddressCompletion
												className="focus:outline-none w-full min-h-[42px] m-2  px-3 border-gray-300 border rounded-md shadow relative -top-2 -left-2"
												value={newProjectStore.getValue("propertyAddress")}
												onChange={(v) => newProjectStore.setValue("propertyAddress", v)}
												placeholder="Enter address"
												onComplete={(v) => {
													// newProjectStore.setValue('propertyAddress', v, true);
												}}
											/>
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="self-stretch h-56 flex-col justify-start items-start gap-6 flex">
							<div className="self-stretch px-2 border-l-2 border-blue-600 justify-start items-start inline-flex">
								<div className="text-slate-700 text-sm font-bold font-['Hanken Grotesk'] leading-tight">Insurance info</div>
							</div>
							<div className="self-stretch h-[180px] flex-col justify-start items-start gap-2 flex">
								<div className="self-stretch justify-start items-start gap-4 inline-flex">
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<NewProjectWizardSimpleCombobox
												title={
													"Insurance Carrier" +
													(userStore.requiredFields.includes("insuranceCarrier") ? " *" : "")
												}
												options={[...userStore.carrierList].sort()}
												value={
													newProjectStore.estimaticsData.insuranceCarrier ||
													(userStore.carrierList.includes("Other")
														? "Other"
														: [...userStore.carrierList].sort()[0])
												}
												onChange={(v) => newProjectStore.setValue("insuranceCarrier", v)}
											/>
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Claim Number {userStore.requiredFields.includes("claimNumber") && "*"}
											</div>
											<NewProjectWizardStyledInput propertyName={"claimNumber"} placeholder="Enter claim number" />
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
								</div>
								<div className="self-stretch justify-start items-start gap-4 inline-flex">
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												TPA {userStore.requiredFields.includes("tpa") && "*"}
											</div>
											{userStore.tpaList.length === 0 && (
												<NewProjectWizardStyledInput propertyName={"tpa"} placeholder="Enter TPA" />
											)}
											{userStore.tpaList.length > 0 && (
												<NewProjectWizardSimpleCombobox
													clearable={true}
													placeholder="Select TPA"
													options={userStore.tpaList.filter((tpa) => !!tpa)}
													value={newProjectStore.estimaticsData.tpa}
													onChange={(v) => newProjectStore.setValue("tpa", v)}
												/>
											)}
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
									<div className="grow shrink basis-0 opacity-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Dummy
											</div>
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="self-stretch  flex-col justify-start items-start gap-6 flex">
							<div className="self-stretch px-2 border-l-2 border-blue-600 justify-start items-start inline-flex">
								<div className="text-slate-700 text-sm font-bold font-['Hanken Grotesk'] leading-tight">Project info</div>
							</div>
							<div className="self-stretch h-[86px] flex-col justify-start items-start gap-2 flex">
								<div className="self-stretch justify-start items-start gap-4 inline-flex">
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex ">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex relative">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Date contacted {userStore.requiredFields.includes("dateContacted") && "*"}
											</div>
											<div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
												<DatePicker
													className="focus:outline-none"
													selected={
														newProjectStore.estimaticsData.dateContacted
															? new Date(newProjectStore.estimaticsData.dateContacted.replace(/\//g, "-"))
															: new Date()
													}
													onChange={(date) => {
														if (date) {
															newProjectStore.setValue("dateContacted", date.toLocaleDateString("en-US"));
														}
													}}
													dateFormat="yyyy/MM/dd"
													isClearable={false}
													placeholderText="Select a date"
												/>
											</div>
											<img
												src="/assets/ui/calendar.svg"
												className="w-5 h-5 absolute right-4 top-[35px] "
												style={{
													pointerEvents: "none",
												}}
											/>
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
									<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
										<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex relative">
											<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
												Date of Loss {userStore.requiredFields.includes("dateOfLoss") && "*"}
											</div>
											<div className="self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
												<DatePicker
													className="focus:outline-none"
													selected={
														newProjectStore.estimaticsData.dateOfLoss
															? new Date(newProjectStore.estimaticsData.dateOfLoss.replace(/\//g, "-"))
															: new Date()
													}
													onChange={(date) => {
														if (date) {
															newProjectStore.setValue("dateOfLoss", date.toLocaleDateString("en-US"));
														}
													}}
													dateFormat="yyyy/MM/dd"
													isClearable={false}
													placeholderText="Select a date"
												/>
											</div>
											<img
												src="/assets/ui/calendar.svg"
												className="w-5 h-5 absolute right-4 top-[35px] "
												style={{
													pointerEvents: "none",
												}}
											/>
										</div>
										<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
											This is a hint text to help user.
										</div>
									</div>
								</div>
							</div>
							<div className="self-stretch justify-start items-start gap-4 inline-flex">
								<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex ">
									<NewProjectWizardSimpleCombobox
										enableTooltip={true}
										clearable={true}
										title={"Office" + (userStore.requiredFields.includes("office") ? " *" : "")}
										placeholder="Office"
										options={userStore.officeList.filter((office) => !!office)}
										value={newProjectStore.estimaticsData.office}
										onChange={(v) => newProjectStore.setValue("office", v)}
									/>
									<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
										This is a hint text to help user.
									</div>
								</div>
								<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
									<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
										<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
											Approximate claim {userStore.requiredFields.includes("approximateClaim") && "*"}
										</div>
										<NewProjectWizardStyledInput
											propertyName={"approximateClaim"}
											placeholder="Enter approximate claim"
										/>
									</div>
									<div className="self-stretch opacity-0 text-slate-600 text-xs font-normal font-['Hanken Grotesk'] leading-[18px]">
										This is a hint text to help user.
									</div>
								</div>
							</div>
							<div className="pb-8 self-stretch justify-start items-start gap-4 inline-flex">
								<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
									<NewProjectWizardSimpleCombobox
										title={"Type of Loss" + (userStore.requiredFields.includes("typeOfLoss") ? " *" : "")}
										options={userStore.typeOfLossList.map((e) => e.type)}
										value={
											newProjectStore.estimaticsData.typeOfLoss ||
											userStore.typeOfLossList.map((e) => e.type)?.[0] ||
											""
										}
										onChange={(v) => newProjectStore.setValue("typeOfLoss", v)}
									/>
								</div>
								<div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
									<NewProjectWizardSimpleCombobox
										title={"Loss Category" + (userStore.requiredFields.includes("category") ? " *" : "")}
										options={
											userStore.typeOfLossList.find(
												(e) =>
													e.type === newProjectStore.estimaticsData.typeOfLoss &&
													e.categories &&
													e.categories!.length
											)
												? userStore.typeOfLossList.find(
														(e) =>
															e.type === newProjectStore.estimaticsData.typeOfLoss &&
															e.categories &&
															e.categories!.length
												  )!.categories!
												: ([] as string[])
										}
										value={newProjectStore.estimaticsData.category}
										onChange={(v) => newProjectStore.setValue("category", v)}
									/>
								</div>
							</div>
						</div>
						{userStore.extraFields.length > 0 && (
							<div className="self-stretch h-[322.44px] flex-col justify-start items-start gap-6 flex">
								<div className="self-stretch px-2 border-l-2 border-blue-600 justify-start items-start inline-flex">
									<div className="text-slate-700 text-sm font-bold font-['Hanken Grotesk'] leading-tight">
										Custom Fields
									</div>
								</div>
								<div className="self-stretch h-[278.44px] flex-col justify-start items-start gap-2 flex">
									{SharedUtils.chunkArray(userStore.extraFields, 2).map((row, index) => {
										return (
											<div key={index} className="self-stretch justify-start items-start gap-4 inline-flex">
												{row.map((item, _index) => {
													return (
														<div
															key={_index}
															className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex"
														>
															<div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
																<div className="text-slate-700 text-sm font-medium font-['Hanken Grotesk'] leading-tight">
																	{item}
																</div>
																<NewProjectWizardStyledInput
																	propertyName={item}
																	placeholder={`Enter value`}
																/>
															</div>
														</div>
													);
												})}
											</div>
										);
									})}
								</div>
							</div>
						)}
						<div className="h32"></div>
					</div>
				</div>
			</div>
			<div className="h-24 px-6 py-6 border-t border-gray-200 justify-between items-start w-full flex">
				<div
					className="w-24 px-[18px] py-2.5 bg-white rounded-lg border border-gray-200 justify-center items-center gap-2 flex cursor-pointer "
					onClick={onCancel}
				>
					<div className="justify-start items-center gap-2 flex ">
						<div className="text-slate-700 text-base font-semibold font-['Hanken Grotesk'] leading-normal select-none">
							Cancel
						</div>
					</div>
				</div>
				<div className=" items-center  flex">
					<div
						className={`h-11 w-24 px-[18px] py-2.5  rounded-lg  border ${
							nextEnabled ? "shadow bg-blue-900 border-blue-900 text-white" : "bg-gray-100 border-gray-200 text-gray-300"
						} justify-center items-center gap-2 flex ${nextEnabled && "btn"}`}
						onClick={() => {
							if (nextEnabled) {
								Analytics.Instance.track("NewProjectWizardStep1Next", {
									estimaticsData: { ...newProjectStore.estimaticsData },
								});
								onNext(2);
							}
						}}
					>
						<div className="justify-start items-center gap-2 flex  ">
							<div className=" text-base font-semibold font-['Hanken Grotesk'] leading-normal select-none">Next</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
