import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { logDebug, logError } from "../../shared/logger";
import { getEstimateStore, getUserStore } from "../../data/DataStores";
import RestAPI from "../../utils/restAPI";
import SharedUtils from "../../shared/SharedUtils";
import { LassoSimpleButton, LassoSimpleButtonFull } from "../../components/ui/utils/LassoButtons";
import bgImage from "./assets/estimatices/bg.jpg";
import LoadingAnimation from "../../components/ui/utils/LoadingAnimation";
enum Instructions {
	NONE = 0,
	ALLOWED = 1,
	DISALLOWED = 2,
}

const LassoMVPLoginPage: React.FC = () => {
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [email, setEmail] = useState("");
	const userStore = getUserStore();
	const [showInstructions, setShowInstructions] = useState<Instructions>(Instructions.NONE);
	const [fetching, setFetching] = useState(false);
	const [showError, setShowError] = useState(false);

	const showExpiredError = location.search.includes("expired-token");

	const onLogin = async () => {
		try {
			setFetching(true);
			try {
				window.localStorage.setItem("passwordless_email", email);
				const data = await RestAPI.fetchAPI(
					"/rest/access/sendSignInLink",
					"GET",
					{
						email: email,
					},
					"data",
					false
				);
				logDebug("data", data);
				setShowInstructions(data?.email ? Instructions.ALLOWED : Instructions.DISALLOWED);
			} catch (err) {
				setShowError(true);
			}
			setFetching(false);
		} catch (err) {
			logError("LassoMVPLandingPage", err);
		}
	};

	useEffect(() => {
		if (email && SharedUtils.testEmail(email)) {
			setButtonEnabled(true);
		} else {
			setButtonEnabled(false);
		}
	}, [email]);

	const checkUserRegistered = async () => {
		const registered = await RestAPI.fetchAPI("/rest/access/isMyUserRegistered");
		if (registered) {
			location.href = "/mvp/test";
		} else {
			// show register flow
		}
	};
	useEffect(() => {
		if (userStore.user) {
			// check if register and redirect or show register form
			checkUserRegistered();
		}
	}, [userStore.user]);

	if (showExpiredError) {
		return (
			<div
				className="flex items-center justify-center h-[100vh]"
				style={{
					backgroundImage: "url(/assets/estimatics/bg.jpg)",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			>
				<div className="w-[800px] h-[464px] pt-14 pb-20 bg-white rounded-xl shadow flex-col justify-start items-center gap-8 inline-flex">
					<div className="w-[88px] h-[88px] justify-center items-center inline-flex">
						<div className="grow shrink basis-0 self-stretch pl-[11.14px] pr-[10.86px] py-[11px] justify-center items-center inline-flex relative">
							<div className="w-[77px] h-[77px] bg-amber-50 rounded-full absolute" />
							<img
								src="/assets/ui/broken.svg"
								className="w-[66px] h-[66px] relative flex-col justify-start items-start flex"
							/>
						</div>
					</div>
					<div className="self-stretch h-[132px] px-12 bg-white flex-col justify-start items-center gap-6 flex">
						<div className="self-stretch h-[132px] flex-col justify-start items-center gap-4 flex">
							<div className="self-stretch text-center text-gray-900 text-[32px] font-semibold font-['Hanken Grotesk'] leading-[38.40px]">
								Login Link Expired
							</div>
							<div className="self-stretch text-center">
								<span className="text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight no-break">
									The link you clicked on has expired. For security reasons, our links are only valid for a limited time.
									<br />
									<br />
									Please try logging in again.
								</span>
								<span className="text-blue-900 text-sm font-normal font-['Inter'] underline leading-tight"></span>
							</div>
						</div>
					</div>
					<div className="w-[800px] px-12 justify-center items-start inline-flex">
						<div
							className="btn w-[132px] px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex"
							onClick={() => {
								location.href = "/";
							}}
						>
							<div className="no-break text-white text-base font-semibold font-['Hanken Grotesk'] leading-normal">
								Try again
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else if (showInstructions === Instructions.ALLOWED) {
		return (
			<div
				className="flex items-center justify-center h-[100vh]"
				style={{
					backgroundImage: "url(/assets/estimatics/bg.jpg)",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			>
				<div className="w-[800px] h-[230px] pt-14 pb-20 bg-white rounded-xl shadow flex-col justify-start items-center gap-8 inline-flex">
					<div className="self-stretch h-[94px] px-12 bg-white flex-col justify-start items-center gap-6 flex">
						<div className="self-stretch h-[94px] flex-col justify-start items-center gap-4 flex">
							<div className="self-stretch text-center text-gray-900 text-[32px] font-semibold font-['Hanken Grotesk'] leading-[38.40px]">
								Check your email for the login link
							</div>
							<div className="self-stretch text-center text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
								Thank you for submitting your email address. We've sent a magic link to your inbox, which will allow you to
								securely log in to your Lasso Estimates account.
								<br />
								<br />
								Please click on the link in your email to access your account and start streamlining your estimation
								process.
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else if (showInstructions === Instructions.DISALLOWED) {
		return (
			<div
				className="flex items-center justify-center h-[100vh]"
				style={{
					backgroundImage: "url(/assets/estimatics/bg.jpg)",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			>
				<div className="w-[800px] h-[464px] pt-14 pb-20 bg-white rounded-xl shadow flex-col justify-start items-center gap-8 inline-flex">
					<div className="w-[88px] h-[88px] justify-center items-center inline-flex">
						<div className="grow shrink basis-0 self-stretch pl-[11.14px] pr-[10.86px] py-[11px] justify-center items-center inline-flex">
							<img
								src="/assets/ui/no-user.svg"
								className="w-[66px] h-[66px] relative flex-col justify-start items-start flex"
							/>
						</div>
					</div>
					<div className="self-stretch h-[132px] px-12 bg-white flex-col justify-start items-center gap-6 flex">
						<div className="self-stretch h-[132px] flex-col justify-start items-center gap-4 flex">
							<div className="self-stretch text-center text-gray-900 text-[32px] font-semibold font-['Hanken Grotesk'] leading-[38.40px]">
								We could not find an account for
								<br />
								that email address
							</div>
							<div className="self-stretch text-center">
								<span className="text-slate-600 text-sm font-normal font-['Hanken Grotesk'] leading-tight no-break">
									if you are unable to log-in please reach out to your Lasso administrator or the Lasso support team at
								</span>
								<br />
								<span className="text-blue-900 text-sm font-normal font-['Inter'] underline leading-tight">
									admin@goLasso.ai
								</span>
							</div>
						</div>
					</div>
					<div className="w-[800px] px-12 justify-center items-start inline-flex">
						<div
							className="btn w-[132px] px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex"
							onClick={() => {
								location.reload();
							}}
						>
							<div className="no-break text-white text-base font-semibold font-['Hanken Grotesk'] leading-normal">
								Try again
							</div>
						</div>
					</div>
				</div>
				{/* <div className="w-[800px] h-[404px] pt-14 pb-20 bg-white rounded-xl shadow flex-col justify-start items-center gap-8 inline-flex">
				<img className="w-[66px] h-[66px]" src="/assets/ui/passwordless-error.png" />
				<div className="self-stretch h-[94px] px-12 bg-white flex-col justify-start items-center gap-6 flex">
					<div className="self-stretch h-[94px] flex-col justify-start items-center gap-4 flex">
						<div className="self-stretch text-center text-gray-900 text-[32px] font-semibold font-['Hanken Grotesk'] leading-[38.40px]">oh snap </div>
						<div className="self-stretch text-center text-slate-600 text-sm font-normal font-['Inter'] leading-tight">email is not allowed please contact admin .</div>
					</div>
				</div>
				<div className="w-[800px] px-12 justify-center items-start inline-flex">
					<div className="w-[108px] px-[18px] py-2.5 bg-blue-900 rounded-lg shadow border border-blue-900 justify-center items-center gap-2 flex">
						<LassoSimpleButtonFull caption='Try Again' onClick={() => {
							location.reload();
						}}></LassoSimpleButtonFull>
					</div>
				</div>
			</div> */}
			</div>
		);
	}
	return (
		<div
			className="flex items-center justify-center h-[100vh]"
			style={{
				backgroundImage: "url(/assets/estimatics/bg.jpg)",
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<div className="w-[800px] h-[306px] pt-14 pb-20 bg-white rounded-xl shadow flex-col justify-start items-center gap-8 inline-flex">
				<div className="self-stretch h-[94px] px-12 bg-white flex-col justify-start items-center gap-6 flex">
					<div className="self-stretch h-[94px] flex-col justify-start items-center gap-4 flex  select-none">
						<div className="self-stretch text-center text-gray-900 text-[32px] font-semibold font-['Hanken Grotesk'] leading-[38.40px]">
							Welcome to Lasso Estimates Portal
						</div>
						<div className="self-stretch text-center text-slate-600 text-sm font-normal font-['Inter'] leading-tight">
							Simplify your estimating process with Lasso. To access your account, enter your email below
						</div>
					</div>
				</div>
				<div className="w-[800px] px-12 justify-start items-start inline-flex">
					<div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
						<div className="self-stretch h-11 flex-col justify-start items-start gap-1.5 flex">
							<div className="self-stretch px-3.5 py-2.5 bg-white rounded-tl-lg rounded-bl-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
								<div className="justify-start items-center gap-2 flex">
									<input
										onKeyDown={(e) => {
											if (e.key === "Enter" && buttonEnabled) {
												onLogin();
											}
										}}
										type="text"
										className="w-[500px] border-0 focus:outline-none"
										placeholder="Email Address"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
							</div>
						</div>
					</div>
					<LassoSimpleButton disabled={!buttonEnabled} onClick={onLogin} caption="Send Login Link" />
				</div>
			</div>
			{fetching && <LoadingAnimation />}
		</div>
	);
};
export default LassoMVPLoginPage;
